<template>
  <div class="button-container">
    <button :class="{selected: value == option.id}" v-for="(option, index) in options" :key="index"
            @click.prevent="changeValue(option)">{{option.label}}</button>
    <input type="text" v-if="otherOption" name="otherOption" maxlength="80" placeholder="Otro (Especifíque)" v-model="other" @input="inputValue">
  </div>
</template>

<script>
export default {
  name: "RadioButtons",
  props: {
    options: { type: Array,  required: true,  },
    otherOption: { type: Boolean, required: false },
    value:   { type: [String, Boolean, Date], required: false, },
    isDocument: {type: Boolean, required: false },
  },
  data() {
    return {
      other: ""
    }
  },
  methods: {
    changeValue(option) {
      if(this.isDocument) {
        this.$emit('updateTypeIdentification', option.id);
      } else { 
        this.$emit('input', option.id);
      }
    },
    inputValue() {
      const foundOption = this.options.find(option => option.label === this.other);
      if (foundOption) {
        this.other = "";
        this.changeValue(foundOption);
      } else {
        this.$emit('input', this.other);
      }
    },
    changeValueForDocument(option) {
      this.$emit('input', option);
    }
  }
}
</script>

<style lang="scss">
.button-container {
  display: grid;
  grid-column-gap: 16px;
  grid-auto-flow: column;
  justify-content: start;

  button {
    display: flex;
    width: max-content;
    border: 1px solid $dark-brown-color;
    background-color: transparent;
    border-radius: 8px;
    color: $dark-brown-color;
    font-family: $font-secondary !important;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    height: 32px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.selected {
      background: linear-gradient(0deg, rgba(255, 153, 0, 0.2), rgba(255, 153, 0, 0.2)), #FFFFFF;
      border: 1px solid $orange-color;
    }
  }

  input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 16px;
    width: 192px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #A9A9A9;
    border-radius: 8px;
  }
}
</style>
