const winparams = `dependent=yes, locationbar=no, scrollbars=yes, menubar=yes, resizable, screenX=50, screenY=50, width=850, height=1050`;

function decodeBase64(encodedData) {
    let binaryString = window.atob(encodedData);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}

function isMobile() {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
}

function isFirefox() {
    return navigator.userAgent.toLowerCase().includes('firefox');
}

function getMimeType(type) {
    switch (type) {
        case 'pdf':
            return 'application/pdf';
        case 'jpg':
        case 'jpeg':
            return 'image/jpeg';
        default:
            return 'application/octet-stream';
    }
}

//------------ public ------------//

function printPdf(encodedData, name, fileType = 'pdf') {
    if (isMobile()) {
        download(encodedData, name, fileType);
    } else {
        try {
            if (isFirefox()) {
                openInTab(encodedData, fileType);
            } else {
                openInWindow(encodedData, name, fileType);
            }
        } catch (error) {
            download(encodedData, name, fileType);
        }
    }
}

function openInWindow(encodedData, name, fileType) {
    const mimeType = getMimeType(fileType);
    const printWindow = window.open(name, name, winparams);
    printWindow.document.write(
        `<embed width=100% height=100% type="${mimeType}" src="data:${mimeType};base64, ${encodedData}"></embed>`
    );
    printWindow.document.close();
    printWindow.focus();
    setTimeout(() => {
        printWindow.print();
    }, 5000);
}

function download(encodedData, name, fileType) {
    const mimeType = getMimeType(fileType);
    const fileData = decodeBase64(encodedData);
    const blob = new Blob([fileData], { type: mimeType });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
    }

    const link = window.URL.createObjectURL(blob);
    const el = document.createElement('a');
    el.href = link;
    el.download = name;
    el.click();

    setTimeout(() => {
        window.URL.revokeObjectURL(link);
    }, 100);
}

function openInTab(encodedData, fileType) {
    const mimeType = getMimeType(fileType);
    const fileData = decodeBase64(encodedData);
    const blob = new Blob([fileData], { type: mimeType });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
    }

    const link = window.URL.createObjectURL(blob);
    window.open(link);
}

const fileManager = {
    printPdf,
    openInWindow,
    download,
};

export default fileManager;
