<template>
    <div class="file-wrapper">
        <div class="file-wrapper__header">
            <h1>Documentación Requerida</h1>
            <p>Los documentos que necesitas para poder aplicar a cualquiera de nuestras vacantes</p>
        </div>
        <div class="file-wrapper__body">
            <file-uploader ref="imss" v-model="form" @onInput="updateForm" :attr="files['imss']" :applicantName="applicantName" />
            <file-uploader ref="birthCertificate" v-model="form" @onInput="updateForm" :attr="files['birthCertificate']" :applicantName="applicantName" />
            <file-uploader ref="poa" v-model="form" @onInput="updateForm" :attr="files['poa']" :applicantName="applicantName" />
            <file-uploader ref="curp" v-model="form" @onInput="updateForm" :attr="files['curp']" :applicantName="applicantName" />
            <file-uploader ref="ine" v-model="form" @onInput="updateForm" :attr="files['ine']" :applicantName="applicantName" @updateTypeDocument="updateIdentificationDocument"/>
            <file-uploader ref="rfc" v-model="form" @onInput="updateForm" :attr="files['rfc']" :applicantName="applicantName" />
            <file-uploader ref="pos" v-model="form" @onInput="updateForm" :attr="files['pos']" :applicantName="applicantName" />
            <file-uploader ref="driverLicense" v-model="form" @onInput="updateForm" :attr="files['driverLicense']" v-if="isDriver" :applicantName="applicantName" />
        </div>
        <div class="file-wrapper__footer">

            <p class="message__title">Carta de No Antecedentes Penales</p>
            <p class="message">En caso de ser contratado, deberás entregar una Carta de No Antecedentes Penales cuya antigüedad no sea mayor a 1 año a partir de la fecha de su expedición.</p>
            <p class="message">Puedes escanear o tomarle una fotografía a estos documentos y subir cada archivo aquí mismo en este portal. (Únicamente archivos JPG o PDF y no mayores a 2MB).</p>
            <p class="message">Si no tienes alguno de estos documentos al momento de llenar esta solicitud, puedes enviarlos posteriormente al correo: <span class="email">recursoshumanos@lascanadas.com</span>.</p>
            <p class="message">O si lo prefieres, puedes traer una copia de cada documento (no recibimos originales) a nuestra oficina de recursos humanos en Las Cañadas Campamento, de Domingo a Viernes de 8am a 6pm, Sábados de 8am a 1pm.</p>
            <div class="alert">
                <img src="@/assets/info.svg" alt="">
                <p>Para poder considerar tu solicitud, es necesario que toda la documentación requerida haya sido enviada o entregada por cualquier medio antes mencionado. Si falta alguno de los documentos requeridos, tu solicitud no será considerada.</p>
            </div>
        </div>
    </div>
</template>
<script>
import FileUploader from '@/components/files/FileUploader';

const REVERSE_LABEL = '_REVERSO';

export default {
    name: 'JobApplicationFileWrapper',
    components: { FileUploader },
    props: [
        'hasStudies',
        'hasCertificate',
        'isDriver',
        'needHighSchoolOrUniversityCertificate',
        'applicantName'
    ],
    data() {
        return {
            form: new FormData(),
            files: {
              imss: {
                name: 'imss',
                title: 'Documento Oficial que contenga tu número de afiliación al IMSS',
                bothSides: false,
                documentName: 'IMSS'
              },
              birthCertificate: {
                name: 'acta',
                title: 'Acta de Nacimiento',
                bothSides: false,
                documentName: 'Acta_Nac'
              },
              poa: {
                name: 'comprobante_de_domicilio',
                title: 'Comprobante de Domicilio Actual (No mayor a 3 meses de antigüedad)',
                bothSides: false,
                documentName: 'Comp_Dom'
              },
              curp: {
                name: 'curp',
                title: 'CURP',
                bothSides: false,
                documentName: 'CURP'
              },
              ine: {
                title: 'INE, Cartilla o Pasaporte',
                optionalDocumentTypes: {
                    instructions: 'Selecciona el tipo de identificación',
                    options: [
                        {label: 'INE', id: 'INE'},
                        {label: 'CARTILLA', id: 'MILITARY_ID'},
                        {label: 'PASAPORTE', id: 'PASSPORT'}
                    ]
                }
              },
              rfc: {
                name: 'rfc',
                title: 'RFC - Registro Federal de Contribuyentes (Documento Oficial expedido por el SAT)',
                bothSides: false,
                documentName: 'RFC'
              },
              pos: {
                name: 'comprobante_de_estudios',
                title: 'Último comprobante de estudios con Promedio de Aprovechamiento',
                bothSides: false,
                documentName: 'Comp_Est'
              },
              driverLicense: {
                name: 'licencia_de_conducir',
                title: 'Licencia de conducir por ambos lados',
                bothSides: true,
                instructions: 'Indica la forma en la que proporcionarás tu Licencia de Conducir',
                documentName: 'Lic_Cond'
              }
            }
        }
    },
    watch: {
        'needHighSchoolOrUniversityCertificate'() {
            if (this.needHighSchoolOrUniversityCertificate) {
                this.files.pos = {
                    name: 'comprobante_prepa_uni',
                    title: 'Comprobante de Preparatoria o Universidad con Promedio de Aprovechamiento',
                    bothSides: false,
                    documentName: 'Comp_Prep_Univ'
                };
            } else {
                this.files.pos = {
                name: 'comprobante_de_estudios',
                title: 'Último comprobante de estudios con Promedio de Aprovechamiento',
                bothSides: false,
                documentName: 'Comp_Est'
              };
            }
        }
    },
    methods: {
        updateForm(value) {
            this.form = value;
        },
        getFiles() {
            const files = new FormData();
            const fileNames = [];
            for(let key of this.form.values()) {

                let index = fileNames.findIndex(name => name === key.name);
                if (index > -1) {
                    key = new File([key], key.name.concat(REVERSE_LABEL), {
                        type: key.type,
                    });
                }
                fileNames.push(key.name);
                files.append('files', key);
            }

            return files;
        },
        updateIdentificationDocument(type) {
            if (type === 'INE') {
                this.files.ine.name = 'ine';
                this.files.ine.documentName = 'INE';
                this.files.ine.instructions = 'Indica la forma en la que proporcionarás tu INE';
                this.files.ine.bothSides = true;
            } else if (type === 'PASSPORT') {
                this.files.ine.name = 'pasaporte';
                this.files.ine.documentName = 'Pasaporte';
                this.files.ine.bothSides = false;
                delete this.files.ine.instructions;
            } else if (type === 'MILITARY_ID') {
                this.files.ine.name = 'cartilla';
                this.files.ine.documentName = 'Cartilla';
                this.files.ine.bothSides = false;
                delete this.files.ine.instructions;
            }
        },
    },
}

</script>
<style lang="scss" scoped>
.file-wrapper {
    &__header {
        h1 {
            margin-bottom: 24px;
        }
        p {
            font-family: $font-primary;
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            letter-spacing: 0.05em;
            color: $light-gray;
        }
    }

    &__footer {
        margin: 24px 0px;
        .message {
            font-family: $font-primary;
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            letter-spacing: 0.05em;
            color: $light-gray;
            margin-bottom: 16px;

            .email {
                color: $blue-great-color;
                text-decoration: underline;
            }

            .title {
              margin-bottom: 0;
              color: $dark-brown-color;
            }
        }

        .alert {
            display: grid;
            grid-template-columns: auto 1fr;
            background: #FFDEDE;
            border-radius: 8px;
            padding: 8px;
            gap: 8px;

            img {
                align-self: center;
            }

            p {
                font-family: $font-primary;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.05em;
                color: $red-color2;
            }

            @include respond-to("small and down") {
                img {
                    align-self: start;
                }
            }
        }
    }
}
</style>