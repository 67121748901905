<template>
  <default-layout>
  <div class="job-application">
    <div class="job-application__header">
      <div class="job-application__header__title">
        <h1>Llenar solicitud de empleo</h1>
        <h2>{{ jobInfo.title }}</h2>
      </div>
      <button class="btn btn--primary--outer desktop" @click="goBack">Regresar</button>
    </div>
    <div class="job-application__form">
      <validation-observer ref="validationObserver" slim>
        <form id="form" class="form" @submit.prevent="sendApplication" novalidate>
          <section class="job-application__form__section">
            <h3 class="job-application__form__section__title">Datos Personales</h3>
            <div class="columns">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="name" class="field__title">Nombre<span class="required">*</span></label>
                <input v-model="form.applicantName" v-capitalize class="field__input"
                  type="text" name="name" maxlength="80" placeholder="Ingresa tu nombre(s)" @change="updateFileNames">
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="lastname" class="field__title">Apellido Paterno<span class="required">*</span></label>
                <input v-model="form.applicantFatherLastname" v-capitalize class="field__input"
                  type="text" name="lastname" maxlength="80" placeholder="Ingresa tu primer apellido" @change="updateFileNames">
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <div class="field">
                <label for="mother-lastname" class="field__title">Apellido Materno</label>
                <input v-model="form.applicantMotherLastname" v-capitalize class="field__input"
                  type="text" name="mother-lastname" maxlength="80" placeholder="Ingresa tu segundo apellido">
              </div>

              <validation-provider class="field --date" rules="required" v-slot="{ errors }" mode="aggressive" ref="calendar">
                <label for="age" class="field__title">Fecha de nacimiento<span class="required">*</span></label>
                <date-picker
                  mode="date"
                  v-model="form.birthDate"
                  :locale="$t('lang')"
                  :max-date="sixteenYearsAgo"
                  :min-date="sixtyYearsAgo"
                  color="yellow"
                  >
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="date-picker --birth-date">
                      <img src="@/assets/calendar.svg" alt="">
                      <input :value="inputValue" v-on="inputEvents" placeholder="Ingresar"/>
                    </div>
                  </template>
                  <template v-slot:day-content="{ day, dayEvents }" >
                    <div class="day-content"
                      v-if="isDateAvailable(day)"
                      v-on="dayEvents"
                    >
                      <div class="day" :class="{'selected': selectedDay(day)}">
                        {{ day.day }}
                      </div>
                    </div>
                    <div class="day-content"
                      v-else v-on="dayEvents"
                      v-tooltip.top="{content: calendarMessage+currentYear+'.'}"
                      :class="{'disabled': !isDateAvailable(day)}"
                    >
                      <div class="day" :class="{'selected': selectedDay(day)}">
                        {{ day.day }}
                      </div>
                    </div>
                  </template>
                </date-picker>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="gender" class="field__title">Género<span class="required">*</span></label>
                <select name="gender" v-model="form.gender" class="field__input">
                  <option value="" disabled selected>Género</option>
                  <option v-for="gender in options.genders" :value="gender.id" :key="gender.id">{{gender.text}}</option>
                </select>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <div class="field" v-if="form.gender == 'O'">
                <label for="gender-other" class="field__title">Otro</label>
                <input type="text" name="gender-other" v-model="form.otherGender" maxlength="80" placeholder="Especifique" class="field__input">
              </div>
            </div>
            <div class="columns">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="address" class="field__title">Dirección<span class="required">*</span></label>
                <input type="text" name="address" v-model="form.address" maxlength="80" placeholder="Ingresa tu dirección" class="field__input">
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <validation-provider class="field" rules="required|validPhoneNumberFormat" v-slot="{ errors }" mode="lazy">
                <label for="phoneNumber" class="field__title">Teléfono Personal<span class="required">*</span></label>
                <the-mask class="field__input" v-model="form.phoneNumber" type="tel"
                  mask="(###) ###-####" :masked="false" placeholder="(555) 555-5555"/>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <validation-provider class="field" rules="validPhoneNumberFormat" v-slot="{ errors }" mode="lazy">
                <label for="homePhoneNumber" class="field__title">Teléfono de casa</label>
                <the-mask class="field__input" v-model="form.homePhoneNumber" type="tel"
                          mask="(###) ###-####" :masked="false" placeholder="(555) 555-5555"/>
                <span class="field__error">{{ errors[0] }}</span>
              </validation-provider>
            </div>
            <div class="columns personal">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="municipality" class="field__title">Municipio<span class="required">*</span></label>
                <input type="text" name="municipality" v-model="form.municipality" maxlength="80" placeholder="Ingresa el municipio" class="field__input">
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="neighborhood" class="field__title">Colonia o fraccionamiento<span class="required">*</span></label>
                <input type="text" name="neighborhood" v-model="form.neighborhood" maxlength="80" placeholder="Ingresa la colonia o fraccionamiento" class="field__input">
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="nationality" class="field__title">Nacionalidad<span class="required">*</span></label>
                <input type="text" name="nationality" v-model="form.nationality" maxlength="80" placeholder="Ingresa tu nacionalidad" class="field__input">
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="email" class="field__title">Correo electrónico<span class="required">*</span></label>
                <input type="email" name="email" v-model="form.email" placeholder="Ingresa tu correo electrónico" class="field__input">
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
            </div>
            <div class="columns personal">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="height" class="field__title">Estatura (metros)<span class="required">*</span></label>
                <input class="field__input" name="height" v-model="form.height" @change="addDecimalSuffix('height', 'metros', 1)"
                       type="tel" min="1" max="3" placeholder="Ingresa tu estatura en metros"/>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="weight" class="field__title">Peso (Kg)<span class="required">*</span></label>
                <input class="field__input" name="weight" v-model.number="form.weight" @change="addDecimalSuffix('weight', 'kilogramos', 2)"
                       inputmode="numeric" type="text" maxlength="7" placeholder="Ingresa tu peso en kilogramos"/>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <validation-provider class="field" rules="required" mode="aggressive" v-slot="{ errors }">
                <label for="martialStatus" class="field__title">Estado civil<span class="required">*</span></label>
                <select name="maritalStatus" v-model="form.maritalStatus" class="field__input">
                  <option value="" disabled selected>Selecciona</option>
                  <option v-for="status in options.maritalStatus" :value="status.id" :key="status.id">{{status.text}}</option>
                </select>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
            </div>
          </section>

          <section class="job-application__form__section">
            <h3 class="job-application__form__section__title">Hábitos Personales</h3>
            <div class="columns">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="name" class="field__title">Tienes algún pasatiempo<span class="required">*</span></label>
                <input type="text" name="name" v-model="form.hobbies" maxlength="80" placeholder="(Ejemplo: jugar fútbol, ir al gimnasio, etc.)" class="field__input">
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
            </div>
          </section>

          <section class="job-application__form__section">
            <h3 class="job-application__form__section__title">Escolaridad</h3>
            <!--ELEMENTARY-->
            <div class="columns">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="elementarySchoolAccredited" class="field__title">¿Cursaste la educación primaria?<span class="required">*</span></label>
                <radio-buttons name="elementarySchoolAccredited" class="field__radio-buttons" :options="options.yesNoPartial" v-model="form.elementarySchoolAccredited"></radio-buttons>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
            </div>
            <div class="columns" v-if="displayElementarySchool">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="elementarySchoolName" class="field__title">Nombre de la primaria<span class="required">*</span></label>
                <input type="text" name="elementarySchoolName" v-model="form.elementarySchoolName" maxlength="80" placeholder="Nombre de la primaria" class="field__input">
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <validation-provider v-if="isElementarySchoolPartial" class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="elementarySchoolGrade" class="field__title">Último grado cursado<span class="required">*</span></label>
                <select name="elementarySchoolGrade" v-model="form.elementarySchoolGrade" class="field__input">
                  <option value="" disabled selected>Selecciona</option>
                  <option v-for="grade in options.schoolGrades.elementary" :value="grade.id" :key="grade.id">{{grade.text}}</option>
                </select>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <validation-provider v-if="hasElementarySchool" class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="elementarySchoolCertified" class="field__title">¿Recibió certificado?<span class="required">*</span></label>
                <select name="elementarySchoolCertified" v-model="form.elementarySchoolCertified" class="field__input">
                  <option value="" disabled selected>Selecciona</option>
                  <option v-for="option in options.schoolCertificate" :value="option.id" :key="option.id">{{option.text}}</option>
                </select>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
            </div>

            <!--JUNIOR HIGH-->
            <div class="columns" v-if="hasElementarySchool">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="juniorHighSchoolAccredited" class="field__title">¿Cursaste la educación secundaria?<span class="required">*</span></label>
                <radio-buttons name="juniorHighSchoolAccredited" class="field__radio-buttons" :options="options.yesNoPartial" v-model="form.juniorHighSchoolAccredited"></radio-buttons>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
            </div>
            <div class="columns" v-if="displayJuniorHighSchool && hasElementarySchool">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="juniorHighSchoolName" class="field__title">Nombre de la secundaria<span class="required">*</span></label>
                <input type="text" name="juniorHighSchoolName" v-model="form.juniorHighSchoolName" maxlength="80" placeholder="Nombre de la secundaria" class="field__input">
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <validation-provider v-if="isJuniorHighSchoolPartial" class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="juniorHighSchoolGrade" class="field__title">Último grado cursado<span class="required">*</span></label>
                <select name="juniorHighSchoolGrade" v-model="form.juniorHighSchoolGrade" class="field__input">
                  <option value="" disabled selected>Selecciona</option>
                  <option v-for="grade in options.schoolGrades.juniorHigh" :value="grade.id" :key="grade.id">{{grade.text}}</option>
                </select>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <validation-provider v-if="hasJuniorHighSchool" class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="juniorHighSchoolCertified" class="field__title">¿Recibió certificado?<span class="required">*</span></label>
                <select name="juniorHighSchoolCertified" v-model="form.juniorHighSchoolCertified" class="field__input">
                  <option value="" disabled selected>Selecciona</option>
                  <option v-for="option in options.schoolCertificate" :value="option.id" :key="option.id">{{option.text}}</option>
                </select>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
            </div>

            <!--HIGH SCHOOL-->
            <div class="columns" v-if="hasJuniorHighSchool">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="highSchoolAccredited" class="field__title">¿Cursaste la educación preparatoria o bachillerato?<span class="required">*</span></label>
                <radio-buttons name="highSchoolAccredited" class="field__radio-buttons" :options="options.yesNoPartial" v-model="form.highSchoolAccredited"></radio-buttons>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
            </div>
            <div class="columns" v-if="displayHighSchool && hasJuniorHighSchool">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="highSchoolName" class="field__title">Nombre de la preparatoria o bachillerato<span class="required">*</span></label>
                <input type="text" name="highSchoolName" v-model="form.highSchoolName" maxlength="80" placeholder="Nombre preparatoria o bachillerato" class="field__input">
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="highSchoolModality" class="field__title">Modalidad<span class="required">*</span></label>
                <radio-buttons name="highSchoolModality" class="field__radio-buttons__modality" :options="options.schoolModality"
                  v-model="form.highSchoolModality"/>
                <span v-if="errors[0]" class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <validation-provider v-if="isHighSchoolPartial" class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="highSchoolGrade" class="field__title">Último grado cursado<span class="required">*</span></label>
                <select name="highSchoolGrade" v-model="form.highSchoolGrade" @click="enableInputHighSchoolCertified(form.highSchoolGrade, highSchoolGradeOptions)" class="field__input">
                    <option value="" disabled selected>Selecciona</option>
                    <option v-for="grade in highSchoolGradeOptions" :value="grade.id" :key="grade.id">{{grade.text}}</option>
                </select>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <div class="field">
                <label for="highSchoolTechnicalDegree" class="field__title">Carrera técnica</label>
                <input type="text" name="highSchoolTechnicalDegree" v-model="form.highSchoolTechnicalDegree" maxlength="80" placeholder="Nombre de la carrera técnica" class="field__input">
              </div>

              <validation-provider v-if="hasHighSchool && disableHighSchoolCertified" class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="highSchoolCertified" class="field__title">¿Recibió certificado?<span class="required">*</span></label>
                <select name="highSchoolCertified" :disabled="!disableHighSchoolCertified" v-model="form.highSchoolCertified" class="field__input">
                    <option value="" disabled selected>Selecciona</option>
                    <option v-for="option in options.schoolCertificate" :value="option.id" :key="option.id">{{option.text}}</option>
                </select>
                <span v-show="form.highSchoolCertified != '' && !disableHighSchoolCertified" class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
              <div v-else>
                <label for="highSchoolCertified" class="field__title">¿Recibió certificado?</label>
                <select name="highSchoolCertified" :disabled="!disableHighSchoolCertified" v-model="form.highSchoolCertified" class="field__input">
                    <option value="" disabled selected>Selecciona</option>
                    <option v-for="option in options.schoolCertificate" :value="option.id" :key="option.id">{{option.text}}</option>
                </select>
              </div>
            </div>

            <!--UNIVERSITY-->
            <div class="columns" v-if="hasHighSchool && (this.form.highSchoolCertified === 'YES' || this.form.highSchoolCertified === 'IN_PROGRESS')">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="universityAccredited" class="field__title">¿Cursaste alguna Licenciatura o Carrera Profesional?<span class="required">*</span></label>
                <radio-buttons name="universityAccredited" class="field__radio-buttons" :options="options.yesNoPartial" v-model="form.universityAccredited"></radio-buttons>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
            </div>
            <div class="columns" v-if="displayUniversity && hasHighSchool && (this.form.highSchoolCertified === 'YES' || this.form.highSchoolCertified === 'IN_PROGRESS')">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="universityName" class="field__title">Nombre de la universidad<span class="required">*</span></label>
                <input type="text" name="universityName" v-model="form.universityName" maxlength="80" placeholder="Nombre de la universidad" class="field__input">
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="universityModality" class="field__title">Modalidad<span class="required">*</span></label>
                <radio-buttons name="universityModality" class="field__radio-buttons__modality" :options="options.schoolModality"
                  v-model="form.universityModality"/>
                <span v-if="errors[0]" class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <validation-provider v-if="isUniversityPartial" class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="universityGrade" class="field__title">Último grado cursado<span class="required">*</span></label>
                <select name="universityGrade" v-model="form.universityGrade" @click="enableInputUnivercityCertified(form.universityGrade, universityGradeOptions)" class="field__input">
                  <option value="" disabled selected>Selecciona</option>
                  <option v-for="grade in universityGradeOptions" :value="grade.id" :key="grade.id">{{grade.text}}</option>
                </select>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="universityDegree" class="field__title">Carrera o licenciatura<span class="required">*</span></label>
                <input type="text" name="universityDegree" v-model="form.universityDegree" maxlength="80" placeholder="Nombre de la carrera o licenciatura" class="field__input">
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <validation-provider v-if="hasUniversity" class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="universityCertified" class="field__title">¿Recibió certificado?<span class="required">*</span></label>
                <select name="universityCertified" v-model="form.universityCertified" class="field__input">
                  <option value="" disabled selected>Selecciona</option>
                  <option v-for="option in options.schoolCertificate" :value="option.id" :key="option.id">{{option.text}}</option>
                </select>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
            </div>
            <!--CURRENT-->
            <div class="columns">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="currentlyStudying" class="field__title">¿Estás estudiando actualmente?<span class="required">*</span></label>
                <radio-buttons name="currentlyStudying" class="field__radio-buttons" :options="options.yesNo" v-model="form.currentlyStudying"></radio-buttons>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
            </div>
            <div class="columns" v-if="displayCurrentlyStudying">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="currentlySchoolName" class="field__title">Nombre de la escuela actual<span class="required">*</span></label>
                <input type="text" name="currentlySchoolName" v-model="form.currentlySchoolName" maxlength="80" placeholder="Nombre de la escuela actual" class="field__input">
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="currentlySchoolModality" class="field__title">Modalidad<span class="required">*</span></label>
                <select name="currentlySchoolModality" v-model="form.currentlySchoolModality" class="field__input">
                  <option value="" disabled selected>Selecciona</option>
                  <option v-for="modality in options.schoolModalities" :value="modality.id" :key="modality.id">{{modality.text}}</option>
                </select>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="currentlySchoolSchedule" class="field__title">Horario de clases<span class="required">*</span></label>
                <input type="text" name="currentlySchoolSchedule" v-model="form.currentlySchoolSchedule" maxlength="80" placeholder="Horario de clases" class="field__input">
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="currentlySchoolDegree" class="field__title">Carrera, licenciatura o curso especial<span class="required">*</span></label>
                <input type="text" name="currentlySchoolDegree" v-model="form.currentlySchoolDegree" maxlength="80" placeholder="Nombre de la carrera o licenciatura" class="field__input">
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
            </div>
            <div class="columns " v-if="displayCurrentlyStudying">
              <validation-provider v-if="form.currentlySchoolModality === 'SPECIAL_COURSE'" class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="dateInit" class="field__title">Fecha de inicio<span class="required">*</span></label>
                <date-picker mode="date" v-model="form.currentlyDateInitOfCourse" :locale="$t('lang')" color="yellow" >
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="date-picker">
                      <img src="@/assets/calendar.svg" alt="">
                      <input :value="inputValue" v-on="inputEvents" placeholder="Fecha específica"/>
                    </div>
                  </template>
                </date-picker>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <validation-provider v-if="form.currentlySchoolModality === 'SPECIAL_COURSE'" class="field" rules="required|max50" v-slot="{ errors }" mode="aggressive">
                <label for="currentlyCourseDuration" class="field__title">Duración<span class="required">*</span></label>
                <input type="text" name="currentlyCourseDuration" v-model="form.currentlyCourseDuration" min="0" max="50" maxlength="50" placeholder="Duración del curso" class="field__input">
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <validation-provider class="field" v-if="form.currentlySchoolModality != 'SPECIAL_COURSE'" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="currentlySchoolGrade" class="field__title">Grado o semestre<span class="required">*</span></label>
                <select name="currentlySchoolGrade" v-model="form.currentlySchoolGrade" class="field__input">
                  <option value="" disabled selected>Selecciona</option>
                  <option v-for="grade in options.schoolGrades.university" :value="grade.id" :key="grade.id">{{grade.text}}</option>
                </select>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
              <div class="field">
                <label for="currentlySchoolCity" class="field__title">Ciudad en la que se encuentra la escuela a la que asiste actualmente</label>
                <input type="text" name="currentlySchoolCity" v-model="form.currentlySchoolCity" maxlength="80" placeholder="Ingresa la ciudad" class="field__input">
              </div>
              <span class="desktop"></span>
            </div>

            <div class="columns" v-if="form.elementarySchoolAccredited == 'YES'">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="lastGradePointAverage" class="field__title">De acuerdo a la ÚLTIMA institución de educación a la que acudiste, el promedio de aprovechamiento fue de<span class="required">*</span></label>
                <input class="field__input" name="lastGradePointAverage" v-model.number="form.lastGradePointAverage" inputmode="numeric" type="text" maxlength="6" placeholder="Ingresa el promedio" @keyup="checkLastGradePointAverage($event)"/>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
            </div>

            <div class="columns" v-if="jobInfo.area == 'Enfermería'">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="professionalLicense" class="field__title">¿Tienes Cédula Profesional?<span class="required">*</span></label>
                <radio-buttons name="professionalLicense" class="field__radio-buttons" :options="options.yesNo" v-model="form.professionalLicense"></radio-buttons>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
            </div>

            <div class="field" v-if="hasProfessionalLicense && !needProfessionalLicense">
                <label for="professionalLicenseNumber" class="field__title">Número de cédula profesional</label>
                <input class="field__input professional-license" name="professionalLicenseNumber" v-model.number="form.professionalLicenseNumber" inputmode="numeric" type="number" placeholder="Ingresa el número de cédula"/>
            </div>
          </section>

          <section class="job-application__form__section">
            <h3 class="job-application__form__section__title">Conocimientos generales</h3>
            <div class="columns columns--half">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="speaksEnglish" class="field__title">¿Hablas el idioma inglés?<span class="required">*</span></label>
                <radio-buttons name="speaksEnglish" class="field__radio-buttons" :options="options.yesNo" v-model="form.speaksEnglish" ></radio-buttons>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <validation-provider v-if="form.speaksEnglish" class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="englishProficiencyPercentage" class="field__title">¿Qué % del idioma inglés dominas? (%)</label>
                <input class="field__input" ref="englishProficiencyPercentage" type="tel" min="0" max="100" maxlength="3"
                      v-model.trim="form.englishProficiencyPercentage" key="percentage" @change="validatePercentage" placeholder="Ingresa el porcentaje de inglés"/>
                <span class="field__error">{{ errors[0] }}</span>
              </validation-provider>
            </div>

            <div class="columns columns--half">
              <div class="field">
                <label for="operableOfficeEquipment" class="field__title">Equipo de oficina que sabes manejar</label>
                <input type="text" name="operableOfficeEquipment" v-model="form.operableOfficeEquipment" maxlength="255" placeholder="Computadora, Impresora, Fax, etc." class="field__input">
              </div>

              <div class="field">
                <label for="operableSoftware" class="field__title">Programas de software que sabes manejar</label>
                <input type="text" name="operableSoftware" v-model="form.operableSoftware" maxlength="255" placeholder="(Word, Excel, Photoshop, Ilustrador, etc.)" class="field__input">
              </div>
            </div>

            <div class="columns columns--half">
              <div class="field">
                <label for="operableExtraEquipment" class="field__title">Equipo o herramientas adicionales que sabes manejar</label>
                <input type="text" name="operableExtraEquipment" v-model="form.operableExtraEquipment" maxlength="255" placeholder="Equipo de soldadura, herramientas de carpintería, etc." class="field__input">
              </div>

              <div class="field">
                <label for="extraSkills" class="field__title">Habilidades o experiencias adicionales</label>
                <input type="text" name="extraSkills" v-model="form.extraSkills" maxlength="255" placeholder="Otro idioma (especifique), programación de software, etc." class="field__input">
              </div>
            </div>
          </section>

          <section class="job-application__form__section">
            <h3 class="job-application__form__section__title">Estado de salud</h3>
            <div class="columns">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="hasIllness" class="field__title">¿Tienes alguna enfermedad o padecimiento preexistente que te impida laborar?<span class="required">*</span></label>
                <radio-buttons name="hasIllness" class="field__radio-buttons" :options="options.yesNo" v-model="form.hasIllness"></radio-buttons>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
            </div>
            <div class="columns">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive" v-if="form.hasIllness">
                <input type="text" name="name" v-model="form.illness" maxlength="80" placeholder="Sí (Especifique)" class="field__input">
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
            </div>
          </section>

          <section class="job-application__form__section">
            <h3 class="job-application__form__section__title">Historial de empleo</h3>
            <div class="columns">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="isFirstJob" class="field__title">¿Trabajas actualmente o has trabajado anteriormente en otra empresa? Puedes incluir datos de empleos aun que no hayan sido formales (Ej. trabajé con mi tía, trabajé con mis papás, etc)<span class="required">*</span></label>
                <radio-buttons name="isFirstJob" class="field__radio-buttons__history" :options="options.isFirstJob" v-model="form.isFirstJob"></radio-buttons>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
            </div>
            <div class="columns" v-if="form.isFirstJob === false">
              <label for="applicantHistory" class="field__title">Por favor llena los datos de tus últimos 3 empleos.</label>
            </div>
            <div class="columns">
              <table name="applicantHistory" class="history" v-if="form.isFirstJob === false">
                <thead>
                  <tr>
                    <th class="empty"></th>
                    <th>Empleo actual/Último</th>
                    <th>Empleo anterior</th>
                    <th>Empleo anterior</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Nombre de la empresa</th>
                    <td>
                      <validation-provider class="field" rules="required" v-slot="{ errors }" mode="lazy">
                        <input type="text" maxlength="80" placeholder="Empresa SA de CV" v-model="form.applicantHistory[0].companyName">
                        <span class="field__error">{{ $t(errors[0]) }}</span>
                      </validation-provider>
                    </td>
                    <td><input type="text" maxlength="80" v-model="form.applicantHistory[1].companyName"></td>
                    <td><input type="text" maxlength="80" v-model="form.applicantHistory[2].companyName"></td>
                  </tr>
                  <tr>
                    <th>Teléfono de la empresa</th>
                    <td>
                      <validation-provider class="field" rules="validPhoneNumberFormat" v-slot="{ errors }" mode="lazy">
                        <the-mask class="field" v-model="form.applicantHistory[0].companyPhoneNumber" type="tel"
                            mask="(###) ###-####" :masked="false" placeholder="(555) 555-5555" />
                        <span class="field__error">{{ errors[0] }}</span>
                      </validation-provider>
                    </td>
                    <td>
                      <validation-provider class="field" rules="validPhoneNumberFormat" v-slot="{ errors }" mode="lazy">
                        <the-mask class="field" v-model="form.applicantHistory[1].companyPhoneNumber" type="tel"
                            mask="(###) ###-####" :masked="false" placeholder="(555) 555-5555" />
                        <span class="field__error">{{ errors[0] }}</span>
                      </validation-provider>
                    </td>
                    <td>
                      <validation-provider class="field" rules="validPhoneNumberFormat" v-slot="{ errors }" mode="lazy">
                        <the-mask class="field" v-model="form.applicantHistory[2].companyPhoneNumber" type="tel"
                            mask="(###) ###-####" :masked="false" placeholder="(555) 555-5555" />
                        <span class="field__error">{{ errors[0] }}</span>
                      </validation-provider>
                    </td>
                  </tr>
                  <tr>
                    <th>Ciudad donde se encuentra la empresa</th>
                    <td>
                      <validation-provider class="field" rules="required" v-slot="{ errors }" mode="lazy">
                        <input type="text" maxlength="80" placeholder="Ciudad" v-model="form.applicantHistory[0].companyCity">
                        <span class="field__error">{{ $t(errors[0]) }}</span>
                      </validation-provider>
                    </td>
                    <td><input type="text" maxlength="80" v-model="form.applicantHistory[1].companyCity"></td>
                    <td><input type="text" maxlength="80" v-model="form.applicantHistory[2].companyCity"></td>
                  </tr>
                  <tr>
                    <th>Mes y Año en que empezó a trabajar en esta empresa</th>
                    <td>
                      <div class="field__job-select">
                        <select name="workDateStart" v-model="form.applicantHistory[0].workDateStartMonth" class="field__input" :class="{'option-placeholder': form.applicantHistory[0].workDateStartMonth === ''}">
                          <option value="" disabled selected>Mes</option>
                          <option v-for="month in monthsOptions" :value="month.id" :key="month.id">{{month.text}}</option>
                        </select>
                        <select name="workDateStart" v-model="form.applicantHistory[0].workDateStartYear" class="field__input" :class="{'option-placeholder': form.applicantHistory[0].workDateStartYear === ''}">
                          <option value="" disabled selected>Año</option>
                          <option v-for="year in yearsOptions" :value="year.id" :key="year.id">{{year.text}}</option>
                        </select>
                      </div>
                      <span class="field__error" v-show="!isWorkDateValid || !isWorkDateStartNull">{{!isWorkDateStartNull ? 'Campo requerido' : 'La fecha de inicio debe ser anterior a la fecha de fin.' }}</span>
                    </td>
                    <td>
                      <div class="field__job-select">
                        <select name="workDateStart" v-model="form.applicantHistory[1].workDateStartMonth" class="field__input" :class="{'option-placeholder': form.applicantHistory[1].workDateStartMonth === ''}">
                          <option value="" disabled selected>Mes</option>
                          <option v-for="month in monthsOptions" :value="month.id" :key="month.id">{{month.text}}</option>
                        </select>
                        <select name="workDateStart" v-model="form.applicantHistory[1].workDateStartYear" class="field__input" :class="{'option-placeholder': form.applicantHistory[1].workDateStartYear === ''}">
                          <option value="" disabled selected>Año</option>
                          <option v-for="year in yearsOptions" :value="year.id" :key="year.id">{{year.text}}</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <div class="field__job-select">
                        <select name="workDateStart" v-model="form.applicantHistory[2].workDateStartMonth" class="field__input" :class="{'option-placeholder': form.applicantHistory[2].workDateStartMonth === ''}">
                          <option value="" disabled selected>Mes</option>
                          <option v-for="month in monthsOptions" :value="month.id" :key="month.id">{{month.text}}</option>
                        </select>
                        <select name="workDateStart" v-model="form.applicantHistory[2].workDateStartYear" class="field__input" :class="{'option-placeholder': form.applicantHistory[2].workDateStartYear === ''}">
                          <option value="" disabled selected>Año</option>
                          <option v-for="year in yearsOptions" :value="year.id" :key="year.id">{{year.text}}</option>
                        </select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Mes y Año en que dejó de trabajar en esta empresa</th>
                    <td>
                      <div class="field__job-select">
                        <select name="workDateEnd" v-model="form.applicantHistory[0].workDateEndMonth" class="field__input" :class="{'option-placeholder': form.applicantHistory[0].workDateEndMonth === ''}">
                          <option value="" disabled selected>Mes</option>
                          <option v-for="month in monthsOptions" :value="month.id" :key="month.id">{{month.text}}</option>
                        </select>
                        <select name="workDateEnd" v-model="form.applicantHistory[0].workDateEndYear" class="field__input" :class="{'option-placeholder': form.applicantHistory[0].workDateEndYear === ''}">
                          <option value="" disabled selected>Año</option>
                          <option v-for="year in yearsOptions" :value="year.id" :key="year.id">{{year.text}}</option>
                        </select>
                      </div>
                      <span class="field__error" v-show="!isWorkDateValid || !isWorkDateEndNull">{{!isWorkDateEndNull ? 'Campo requerido' : 'La fecha de fin debe ser posterior a la fecha de inicio.' }}</span>
                    </td>
                    <td>
                      <div class="field__job-select">
                        <select name="workDateEnd" v-model="form.applicantHistory[1].workDateEndMonth" class="field__input" :class="{'option-placeholder': form.applicantHistory[1].workDateEndMonth === ''}">
                          <option value="" disabled selected>Mes</option>
                          <option v-for="month in monthsOptions" :value="month.id" :key="month.id">{{month.text}}</option>
                        </select>
                        <select name="workDateEnd" v-model="form.applicantHistory[1].workDateEndYear" class="field__input" :class="{'option-placeholder': form.applicantHistory[1].workDateEndYear === ''}">
                          <option value="" disabled selected>Año</option>
                          <option v-for="year in yearsOptions" :value="year.id" :key="year.id">{{year.text}}</option>
                        </select>
                      </div>
                    </td>
                    <td>
                      <div class="field__job-select">
                        <select name="workDateEnd" v-model="form.applicantHistory[2].workDateEndMonth" class="field__input" :class="{'option-placeholder': form.applicantHistory[2].workDateEndMonth === ''}">
                          <option value="" disabled selected>Mes</option>
                          <option v-for="month in monthsOptions" :value="month.id" :key="month.id">{{month.text}}</option>
                        </select>
                        <select name="workDateEnd" v-model="form.applicantHistory[2].workDateEndYear" class="field__input" :class="{'option-placeholder': form.applicantHistory[2].workDateEndYear === ''}">
                          <option value="" disabled selected>Año</option>
                          <option v-for="year in yearsOptions" :value="year.id" :key="year.id">{{year.text}}</option>
                        </select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Tiempo que trabajó en esta empresa</th>
                    <td>
                      <validation-provider class="field" rules="required" v-slot="{ errors }" mode="lazy">
                        <input type="text" maxlength="80" placeholder="1 año y 3 meses" v-model="form.applicantHistory[0].durationInCompany">
                        <span class="field__error">{{ $t(errors[0]) }}</span>
                      </validation-provider>
                    </td>
                    <td><input type="text" maxlength="80" v-model="form.applicantHistory[1].durationInCompany"></td>
                    <td><input type="text" maxlength="80" v-model="form.applicantHistory[2].durationInCompany"></td>
                  </tr>
                  <tr>
                    <th>Puesto que desempeñaba</th>
                    <td>
                      <validation-provider class="field" rules="required" v-slot="{ errors }" mode="lazy">
                        <input type="text" maxlength="80" placeholder="Asistente Administrativo" v-model="form.applicantHistory[0].position">
                        <span class="field__error">{{ $t(errors[0]) }}</span>
                      </validation-provider>
                    </td>
                    <td><input type="text" maxlength="80" v-model="form.applicantHistory[1].position"></td>
                    <td><input type="text" maxlength="80" v-model="form.applicantHistory[2].position"></td>
                  </tr>
                  <tr>
                    <th>Sueldo inicial (x semana)</th>
                    <td>

                      <validation-provider class="field" rules="required" v-slot="{ errors }" mode="lazy">
                        <input type="tel" min="1" @change="validateMoney(0, 'weeklySalaryInitial')"
                               placeholder="$1,000 MXN" v-model="form.applicantHistory[0].weeklySalaryInitial">
                        <span class="field__error">{{ $t(errors[0]) }}</span>
                      </validation-provider>
                    </td>
                    <td><input type="tel" min="1" @change="validateMoney(1, 'weeklySalaryInitial')"
                               v-model="form.applicantHistory[1].weeklySalaryInitial"></td>
                    <td><input type="tel" min="1" @change="validateMoney(2, 'weeklySalaryInitial')"
                               v-model="form.applicantHistory[2].weeklySalaryInitial"></td>
                  </tr>
                  <tr>
                    <th>Sueldo final (x semana)</th>
                    <td>
                      <validation-provider class="field" rules="required" v-slot="{ errors }" mode="lazy">
                        <input type="tel" min="1" placeholder="$2,000 MXN" @change="validateMoney(0, 'weeklySalaryFinal')"
                               v-model="form.applicantHistory[0].weeklySalaryFinal">
                        <span class="field__error">{{ $t(errors[0]) }}</span>
                      </validation-provider>
                    </td>
                    <td><input type="tel" min="1" @change="validateMoney(1, 'weeklySalaryFinal')"
                               v-model="form.applicantHistory[1].weeklySalaryFinal"></td>
                    <td><input type="tel" min="1" @change="validateMoney(2, 'weeklySalaryFinal')"
                               v-model="form.applicantHistory[2].weeklySalaryFinal"></td>
                  </tr>
                  <tr>
                    <th>¿Motivo por el cual dejaste de trabajar en esta empresa?</th>
                    <td>
                      <validation-provider class="field" rules="required" v-slot="{ errors }" mode="lazy">
                        <input type="text" maxlength="80" placeholder="Renuncia Voluntaria" v-model="form.applicantHistory[0].quittingReason">
                        <span class="field__error">{{ $t(errors[0]) }}</span>
                      </validation-provider>
                    </td>
                    <td><input type="text" maxlength="80" v-model="form.applicantHistory[1].quittingReason"></td>
                    <td><input type="text" maxlength="80" v-model="form.applicantHistory[2].quittingReason"></td>
                  </tr>
                  <tr>
                    <th>Nombre de tu jefe o supervisor directo</th>
                    <td>
                      <validation-provider class="field" rules="required" v-slot="{ errors }" mode="lazy">
                        <input v-model="form.applicantHistory[0].supervisorName" v-capitalize
                          type="text" maxlength="80" placeholder="Nombre y Apellido">
                        <span class="field__error">{{ $t(errors[0]) }}</span>
                      </validation-provider>
                    </td>
                    <td>
                      <input v-model="form.applicantHistory[1].supervisorName" v-capitalize
                        type="text" maxlength="80">
                    </td>
                    <td>
                      <input v-model="form.applicantHistory[2].supervisorName" v-capitalize
                        type="text" maxlength="80">
                      </td>
                  </tr>
                  <tr>
                    <th>Puesto de tu jefe o supervisor directo:</th>
                    <td>
                      <validation-provider class="field" rules="required" v-slot="{ errors }" mode="lazy">
                        <input type="text" maxlength="80" placeholder="Gerente General" v-model="form.applicantHistory[0].supervisorPosition">
                        <span class="field__error">{{ $t(errors[0]) }}</span>
                      </validation-provider>
                    </td>
                    <td><input type="text" maxlength="80" v-model="form.applicantHistory[1].supervisorPosition"></td>
                    <td><input type="text" maxlength="80" v-model="form.applicantHistory[2].supervisorPosition"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>

          <section class="job-application__form__section">
            <h3 class="job-application__form__section__title">Referencias personales</h3>
            <label for="personalReferences" class="field__title">Proporciona los datos de contacto de hasta 3 personas a quienes podamos llamar para tratar de localizarte en caso de que no podamos localizarte en el número telefónico que proporcionaste en tus datos personales</label>
            <table name="personalReferences" class="personal-references">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Teléfono</th>
                  <th>Parentesco/Relación</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="field">
                      <input type="text" maxlength="80" v-model="form.personalReferences[0].name" v-capitalize>
                    </div>
                  </td>
                  <td>
                    <div class="field">
                      <validation-provider class="field" rules="validPhoneNumberFormat" v-slot="{ errors }" mode="lazy">
                        <the-mask v-model="form.personalReferences[0].phoneNumber" type="tel"
                            mask="(###) ###-####" placeholder="(555) 555-5555" :masked="false" />
                        <span class="field__error">{{ errors[0] }}</span>
                      </validation-provider>
                    </div>
                  </td>
                  <td>
                    <div class="field">
                      <input type="text" maxlength="80" v-model="form.personalReferences[0].relationship">
                    </div>
                  </td>
                </tr>
                <tr>
                  <td><input type="text" maxlength="80" v-model="form.personalReferences[1].name" v-capitalize></td>
                  <td>
                    <validation-provider class="field" rules="validPhoneNumberFormat" v-slot="{ errors }" mode="lazy">
                      <the-mask v-model="form.personalReferences[1].phoneNumber" type="tel"
                          mask="(###) ###-####" placeholder="(555) 555-5555" :masked="false" />
                      <span class="field__error">{{ errors[0] }}</span>
                    </validation-provider>
                  </td>
                  <td><input type="text" maxlength="80" v-model="form.personalReferences[1].relationship"></td>
                </tr>
                <tr>
                  <td><input type="text" maxlength="80" v-model="form.personalReferences[2].name" v-capitalize></td>
                  <td>
                    <validation-provider class="field" rules="validPhoneNumberFormat" v-slot="{ errors }" mode="lazy">
                      <the-mask v-model="form.personalReferences[2].phoneNumber" type="tel"
                          mask="(###) ###-####" placeholder="(555) 555-5555" :masked="false" />
                      <span class="field__error">{{ errors[0] }}</span>
                    </validation-provider>
                  </td>
                  <td><input type="text" maxlength="80" v-model="form.personalReferences[2].relationship"></td>
                </tr>
              </tbody>
            </table>
          </section>

          <section class="job-application__form__section">
            <h3 class="job-application__form__section__title">Información general</h3>
            <div class="columns columns--cars">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="hasVehicle" class="field__title">¿Tienes automóvil propio?<span class="required">*</span></label>
                <radio-buttons name="hasVehicle" class="field__radio-buttons" :options="options.yesNo" v-model="form.hasVehicle"></radio-buttons>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="hasDriverLicense" class="field__title">¿Tienes licencia de conducir vigente?<span class="required">*</span></label>
                <radio-buttons name="hasDriverLicense" class="field__radio-buttons" :options="options.yesNo" v-model="form.hasDriverLicense"></radio-buttons>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
            </div>
            <div class="columns columns--cars" v-if="form.hasDriverLicense">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="hasDriverLicense" class="field__title">¿Qué tipo de licencia tienes?</label>
                <radio-buttons name="hasDriverLicense" class="field__radio-buttons" :options="options.driverLicenseTypes" v-model="form.driverLicenseType"></radio-buttons>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="driverLicenseEmitter" class="field__title">Autoridad que emitió la licencia de conducir</label>
                <radio-buttons name="driverLicenseEmitter" class="field__radio-buttons" :options="options.driverLicenseEmitters" v-model="form.driverLicenseEmitter"></radio-buttons>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive" v-if="form.driverLicenseEmitter == 'STATE'">
                <label for="driverLicenseState" class="field__title">Estado donde emitió su licencia</label>
                <select name="driverLicenseState" v-model="form.driverLicenseState" class="field__input">
                  <option value="" disabled selected>Selecciona</option>
                  <option v-for="state in options.states" :value="state.id" :key="state.id">{{state.text}}</option>
                </select>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
            </div>
            <div class="columns">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="isPublicTransportUser" class="field__title">¿En caso de ser contratado, utilizarás transporte público para presentarte a laborar?<span class="required">*</span></label>
                <radio-buttons name="isPublicTransportUser" class="field__radio-buttons" :options="options.yesNo" v-model="form.isPublicTransportUser"></radio-buttons>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
            </div>
            <div class="columns" v-if="form.isPublicTransportUser">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="travelCount" class="field__title">¿Cuántos transportes (cantidad de microbús) requieres para trasladarte desde tu casa a esta empresa?</label>
                <select name="travelCount" v-model="form.travelCount" class="field__input field__input__travel">
                  <option value="" disabled selected>Selecciona</option>
                  <option v-for="index in 10" :value="index" :key="index">{{index}}</option>
                </select>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
            </div>
            <div class="columns">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="hasDebt" class="field__title">¿Tienes algún crédito o adeudo pendiente con alguna institución?<span class="required">*</span></label>
                <radio-buttons name="hasDebt" class="field__radio-buttons" :options="options.yesNo" v-model="form.hasDebt"></radio-buttons>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
            </div>
            <div class="columns" v-if="form.hasDebt">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <radio-button-multiple-selection name="hasDebt" class="field__radio-buttons__creditors" :options="options.creditors" :otherOption="true" v-model="form.creditorName"></radio-button-multiple-selection>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
            </div>
            <div class="columns columns--union">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="unionAffiliated" class="field__title">¿Has estado afiliado a algún sindicato?<span class="required">*</span></label>
                <radio-buttons name="unionAffiliated" class="field__radio-buttons" :options="options.yesNo" v-model="form.unionAffiliated"></radio-buttons>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive" v-if="form.unionAffiliated">
                <label for="unionName" class="field__title">Nombre del sindicato</label>
                <input type="text" name="unionName" v-model="form.unionName" maxlength="80" placeholder="Ingresa el nombre del sindicato" class="field__input">
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
            </div>
            <div class="columns">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="shirtSize" class="field__title">Talla de camiseta o blusa (para el uniforme de trabajo en caso de ser contratado)<span class="required">*</span></label>
                <radio-buttons name="shirtSize" class="field__radio-buttons__shirt-size" :options="options.shirtSizes" v-model="form.shirtSize"></radio-buttons>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
            </div>
            <div class="columns columns--half">
              <div>
                <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                  <label for="dependantCount" class="field__title">¿Cuántas personas dependen de ti?<span class="required">*</span></label>
                  <select name="dependantCount" v-model="form.dependantCount" class="field__input">
                    <option value="" disabled selected>Selecciona</option>
                    <option v-for="index in 11" :value="index - 1" :key="index">{{index - 1}}</option>
                  </select>
                  <span class="field__error">{{ $t(errors[0]) }}</span>
                </validation-provider>
              </div>
              <span class="desktop"></span>
            </div>
            <div class="columns columns--half">
              <div>
                <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                  <label for="housemates" class="field__title">Actualmente vives con<span class="required">*</span></label>
                  <input type="text" name="housemates" v-model="form.housemates" maxlength="80" placeholder="(Ejemplo: Solo, Mis Papás, Esposa, Hijos, etc.)" class="field__input">
                  <span class="field__error">{{ $t(errors[0]) }}</span>
                </validation-provider>
              </div>
              <span class="desktop"></span>
            </div>
            <div class="columns columns">
              <div class="split-column-3">
                <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                  <label for="hasChildren" class="field__title">¿Tienes hijos?<span class="required">*</span></label>
                  <radio-buttons name="hasChildren" class="field__radio-buttons" :options="options.yesNo" v-model="form.hasChildren"></radio-buttons>
                  <span class="field__error">{{ $t(errors[0]) }}</span>
                </validation-provider>

                <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive" v-if="form.hasChildren">
                  <label for="childrenCount" class="field__title">¿Cuántos hijos tienes?<span class="required">*</span></label>
                  <select name="childrenCount" v-model="form.childrenCount" class="field__input">
                    <option value="" disabled selected>Selecciona</option>
                    <option v-for="index in 10" :value="index" :key="index">{{index}}</option>
                  </select>
                  <span class="field__error">{{ $t(errors[0]) }}</span>
                </validation-provider>
                <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive" v-if="form.hasChildren">
                  <label for="childrenCare" class="field__title">¿Quién te los cuida?<span class="required">*</span></label>
                  <input type="text" name="childrenCare" v-model="form.childrenCare" maxlength="255" placeholder="Ingresa quién los cuida" class="field__input">
                  <span class="field__error">{{ $t(errors[0]) }}</span>
                </validation-provider>
              </div>
            </div>
            <div class="columns columns--half" v-if="form.hasChildren && form.childrenCount > 0">
              <div class="children-ages">
                <div v-for="(age, index) in form.childrenAges" :key="index">
                  <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                    <label :for="'child-' + index" class="field__title">Edad hijo {{index + 1}}<span class="required">*</span></label>
                    <input class="field__input" :name="'child-' + index"
                      type="number" min="0" max="99"
                      placeholder="0"
                      v-model="form.childrenAges[index]">
                    <span class="field__error">{{ $t(errors[0]) }}</span>
                  </validation-provider>
                </div>
              </div>
              <span class="desktop"></span>
            </div>
            <div class="columns">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="hasLivedInUSA" class="field__title">¿Has residido en Estados Unidos?<span class="required">*</span></label>
                <radio-buttons name="hasLivedInUSA" class="field__radio-buttons" :options="options.yesNo" v-model="form.hasLivedInUSA"></radio-buttons>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
            </div>
            <div class="columns columns--half">
              <div class="antiquity">
                <label for="timeInEnsenada" class="field__title">¿Cuánto tiempo llevas viviendo en Ensenada?<span class="required">*</span></label>
                <validation-provider class="field" rules="required|max_value:100" v-slot="{ errors }" mode="aggressive">
                  <label for="yearsInEnsenada" class="field__title">Años</label>
                  <input type="number" name="yearsInEnsenada" v-model="form.yearsInEnsenada"
                    min="0" max="100" placeholder="Ingresa años" class="field__input">
                  <span class="field__error">{{ $t(errors[0]) }}</span>
                </validation-provider>
                <validation-provider class="field" rules="required|max_value:11" v-slot="{ errors }" mode="aggressive">
                  <label for="monthsInEnsenada" class="field__title">Meses</label>
                  <input type="number" name="monthsInEnsenada" v-model="form.monthsInEnsenada"
                    min="0" max="11" placeholder="Ingresa meses" class="field__input">
                  <span class="field__error">{{ $t(errors[0]) }}</span>
                </validation-provider>
              </div>
              <span class="desktop"></span>
            </div>
            <div class="columns columns--half">

              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="availabilityDate" class="field__title">En caso de ser contratado ¿Cuándo puedes presentarte a laborar?<span class="required">*</span></label>

                <div class="inline">
                  <radio-buttons name="availabilityDate" class="field__radio-buttons" :options="options.dateAvailavility" v-model="form.availabilityDate"></radio-buttons>
                  <date-picker mode="date" v-model="form.availabilityDate"
                    :locale="$t('lang')" color="yellow" :min-date="new Date()">
                    <template v-slot="{ inputValue, inputEvents }">
                      <div class="date-picker">
                        <img src="@/assets/calendar.svg" alt="">
                        <input :value="inputValue" v-on="inputEvents" placeholder="Fecha específica"/>
                      </div>
                    </template>
                  </date-picker>
                </div>

                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
              <span class="desktop"></span>
            </div>
            <div class="columns">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="isFormerEmployee" class="field__title">¿Has trabajado aquí en Las Cañadas Campamento anteriormente?<span class="required">*</span></label>
                <radio-buttons name="isFormerEmployee" class="field__radio-buttons" :options="options.yesNo" v-model="form.isFormerEmployee"></radio-buttons>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
            </div>
            <div class="columns">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="hasRelativeEmployee" class="field__title">¿Tienes algún pariente o familiar que trabaje actualmente en Las Cañadas Campamento?<span class="required">*</span></label>
                <radio-buttons name="hasRelativeEmployee" class="field__radio-buttons" :options="options.yesNo" v-model="form.hasRelativeEmployee"></radio-buttons>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
            </div>
            <div class="columns columns--half" v-if="form.hasRelativeEmployee">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="relativeEmployeeName" class="field__title">Nombre</label>
                <input type="text" name="relativeEmployeeName" v-model="form.relativeEmployeeName" maxlength="80" placeholder="Ingresa nombre" class="field__input">
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
              <span class="desktop"></span>
            </div>
            <div class="columns">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="hasExternalIncome" class="field__title">¿Tienes otros ingresos?<span class="required">*</span></label>
                <radio-buttons name="hasExternalIncome" class="field__radio-buttons" :options="options.yesNo" v-model="form.hasExternalIncome"></radio-buttons>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
            </div>
            <div class="columns columns--source">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="jobPostSource" class="field__title">¿Cómo se enteró de esta oportunidad de empleo?<span class="required">*</span></label>
                <select name="jobPostSource" v-model="form.jobPostSource" class="field__input">
                  <option value="" disabled selected>Selecciona</option>
                  <option v-for="source in options.jobPostSources" :value="source.id" :key="source.id">{{source.text}}</option>
                </select>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>

              <div class="field" v-if="form.jobPostSource == 'OTHER'">
                <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                  <input type="text" name="jobPostSourceOther" v-model="form.jobPostSourceOther" maxlength="80" placeholder="Especifique" class="field__input">
                  <span class="field__error">{{ $t(errors[0]) }}</span>
                </validation-provider>
              </div>
            </div>
            <div class="columns" v-if="availableJobs && availableJobs.length > 0">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="hasAlternativeApplication" class="field__title">En caso de no ser seleccionado para la vacante a la que estás aplicando. ¿Deseas que consideremos esta solicitud para alguna otra de nuestras vacantes?</label>
                <radio-buttons name="hasAlternativeApplication" class="field__radio-buttons" :options="options.yesNo" v-model="form.hasAlternativeApplication"></radio-buttons>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
            </div>
            <div class="columns" v-if="displayAlternativeApplication">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="alternativeApplication" class="field__title">Selecciona en el orden de interés las vacantes adicionales para las que deseas aplicar con esta solicitud. (puedes seleccionar hasta un máximo de 5 opciones)</label>
                <counter-checkbox name="alternativeApplication" :options="availableJobs" :max="5" v-model="form.alternativeApplication"></counter-checkbox>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
            </div>
            <div class="columns" v-if="jobInfo.area !== 'Enfermería' && needProfessionalLicense && displayAlternativeApplication">
              <validation-provider class="field" rules="required" v-slot="{ errors }" mode="aggressive">
                <label for="professionalLicense" class="field__title">¿Tienes Cédula Profesional para la vacante de enfermería o paramédico?<span class="required">*</span></label>
                <radio-buttons name="professionalLicense" class="field__radio-buttons" :options="options.yesNo" v-model="form.professionalLicense"></radio-buttons>
                <span class="field__error">{{ $t(errors[0]) }}</span>
              </validation-provider>
            </div>
            <div class="field" v-if="hasProfessionalLicense && needProfessionalLicense && form.hasAlternativeApplication">
              <label for="professionalLicenseNumber" class="field__title">Número de cédula profesional</label>
              <input class="field__input professional-license" name="professionalLicenseNumber" v-model.number="form.professionalLicenseNumber" inputmode="numeric" type="number" placeholder="Ingresa el número de cédula"/>
            </div>
          </section>
        </form>
      </validation-observer>
      <job-application-file-wrapper ref="fileWrapper"
        :applicantName="getDocumentName"
        :hasStudies="hasElementarySchool"
        :hasCertificate="hasAnyGradeCertificate"
        :isDriver="isDriver"
        :needHighSchoolOrUniversityCertificate="needHighSchoolOrUniversityCertificate"
      />
    </div>
    <div class="job-application__recaptcha">
      <vue-recaptcha class="" ref="recaptcha" @verify="onVerify" @expired="onExpired" :sitekey="recaptchaSiteKey">
      </vue-recaptcha>
    </div>
    <div class="job-application__footer">
      <button class="btn btn--primary--outer mobile" @click="goBack">Regresar</button>
      <div>
        <button v-show="!loading" form="form" type="submit" class="btn" :class="{ disabled: !formValid } ">Enviar</button>
        <mini-loader v-show="loading" class="loader"/>
      </div>

    </div>
    <div class="job-application__error" v-if="validateForm !== null && !validateForm">
      <span>Faltan algunos campos requeridos de completar</span>
    </div>
  </div>
  <leave-application-modal ref="leaveApplicationModal"/>
  </default-layout>
</template>

<script>
import JobApplicationFileWrapper from '@/components/files/JobApplicationFileWrapper.vue';
import CounterCheckbox from '@/components/forms/CounterCheckbox';
import RadioButtonMultipleSelection from '@/components/forms/RadioButtonMultipleSelection';
import LeaveApplicationModal from '@/components/modal/LeaveJobApplicationConfirmation';
import moment from "moment";
import DatePicker from 'v-calendar/lib/components/date-picker.umd'; //implementando nueva version
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { length, max, max_value, required } from "vee-validate/dist/rules";
import { VueRecaptcha } from 'vue-recaptcha';
import { TheMask } from 'vue-the-mask';
import RadioButtons from "../../components/forms/RadioButtons.vue";
import DefaultLayout from "../../layouts/DefaultLayout.vue";
extend("required", { ...required, message: 'validations.requiredField'});
extend("phoneLength", { ...length, message: 'validations.invalidPhoneNumber'});
extend('max_value', {...max_value, message: 'Valor invalido'});
extend('max50', {
    ...max,
    validate(value) {
        return value.length <= 50;
    },
    message: 'Longitud máxima de 50 caracteres'
});
extend('validPhoneNumberFormat', {
    validate: value => {
        const phoneNumberPattern = /^\d{10}$/;
        return phoneNumberPattern.test(value);
    },
    message: 'Use el formato (###) ###-####', // Custom error message
});


const grades = [
  {id: 'GRADE_1ST',  text:  '1er'},
  {id: 'GRADE_2ND',  text:  '2do'},
  {id: 'GRADE_3RD',  text:  '3er'},
  {id: 'GRADE_4TH',  text:  '4to'},
  {id: 'GRADE_5TH',  text:  '5to'},
  {id: 'GRADE_6TH',  text:  '6to'},
  {id: 'GRADE_7TH',  text:  '7mo'},
  {id: 'GRADE_8TH',  text:  '8vo'},
  {id: 'GRADE_9TH',  text:  '9no'},
  {id: 'GRADE_10TH', text: '10mo'},
  {id: 'GRADE_11TH', text: '11vo'},
  {id: 'GRADE_12TH', text: '12vo'},
];
const LOWER_AGE_LIMIT = 18;
const UPPER_AGE_LIMIT = 65;

const MONTHS = [
  { id: 'JANUARY', text: 'Enero' },
  { id: 'FEBRUARY', text: 'Febrero' },
  { id: 'MARCH', text: 'Marzo' },
  { id: 'APRIL', text: 'Abril' },
  { id: 'MAY', text: 'Mayo' },
  { id: 'JUNE', text: 'Junio' },
  { id: 'JULY', text: 'Julio' },
  { id: 'AUGUST', text: 'Agosto' },
  { id: 'SEPTEMBER', text: 'Septiembre' },
  { id: 'OCTOBER', text: 'Octubre' },
  { id: 'NOVEMBER', text: 'Noviembre' },
  { id: 'DECEMBER', text: 'Diciembre' }
];

export default {
  name: "JobApplication",
  metaInfo() {
    return {
      title: 'Solicitud de empleo'
    }
  },
  watch: {
    'form.childrenCount'() {
      const length = this.form.childrenCount || 0;
      this.form.childrenAges = new Array(length).fill('', 0, length)
    },
    'form.highSchoolModality'() {
      if(this.form.highSchoolGrade)
        this.form.highSchoolGrade = null;
    },
    'form.universityModality'() {
      if(this.form.universityGrade)
        this.form.universityGrade = null;
    },
    'form.hasDriverLicense'() {
      if(this.form.alternativeApplication && !this.form.hasDriverLicense) {
        let driverJobs = this.jobs.filter(job => job.area === 'Chofer');
        if(driverJobs) driverJobs = driverJobs.map(job => job.titleId);
        this.form.alternativeApplication = this.form.alternativeApplication.filter(job => !driverJobs.includes(job));
      }
    }
  },
  components: {
    DatePicker,
    DefaultLayout,
    ValidationObserver,
    ValidationProvider,
    RadioButtons,
    JobApplicationFileWrapper,
    TheMask,
    VueRecaptcha,
    LeaveApplicationModal,
    RadioButtonMultipleSelection,
    CounterCheckbox,
    // DatePickerSingle
  },
  data() {
    return {
      jobInfo: {},
      today: null,
      showDatePicker: false,
      form: {
        jobPostId: null,
        applicantName: null,
        applicantFatherLastname: null,
        applicantMotherLastname: null,
        birthDate: null,
        gender: '',
        otherGender: null,
        address: null,
        homePhoneNumber: null,
        phoneNumber: null,
        municipality: null,
        neighborhood: null,
        nationality: null,
        email: null,
        height: null,
        weight: null,
        maritalStatus: '',
        hobbies: null,
        elementarySchoolAccredited: null,
        elementarySchoolName: null,
        elementarySchoolGrade: '',
        elementarySchoolCertified: '',
        juniorHighSchoolAccredited: null,
        juniorHighSchoolName: null,
        juniorHighSchoolGrade: '',
        juniorHighSchoolCertified: '',
        highSchoolAccredited: null,
        highSchoolName: null,
        highSchoolGrade: '',
        highSchoolTechnicalDegree: null,
        highSchoolCertified: '',
        highSchoolModality: null,
        universityAccredited: null,
        universityName: null,
        universityGrade: '',
        universityDegree: null,
        universityCertified: '',
        universityModality: null,
        currentlyStudying: null,
        currentlySchoolName: null,
        currentlySchoolSchedule: null,
        currentlySchoolDegree: null,
        currentlySchoolCity: null,
        currentlySchoolGrade: '',
        currentlySchoolModality: '',
        currentlyDateInitOfCourse: null,
        currentlyCourseDuration: null,
        lastGradePointAverage: null,
        professionalLicense: null,
        professionalLicenseNumber: null,
        speaksEnglish: null,
        englishProficiencyPercentage: null,
        operableOfficeEquipment: null,
        operableSoftware: null,
        operableExtraEquipment: null,
        extraSkills: null,
        hasIllness: null,
        illness: null,
        isFirstJob: null,
        applicantHistory: [
          {
            order: 0,
            companyName: '',
            companyPhoneNumber: null,
            companyCity: null,
            durationInCompany: '',
            workDateStartMonth: '',
            workDateStartYear: '',
            workDateEndMonth: '',
            workDateEndYear: '',
            position: '',
            weeklySalaryInitial: null,
            weeklySalaryFinal: null,
            quittingReason: null,
            supervisorName: null,
            supervisorPosition: null
          },
          {
            order: 1,
            companyName: '',
            companyPhoneNumber: null,
            companyCity: null,
            durationInCompany: '',
            workDateStartMonth: '',
            workDateStartYear: '',
            workDateEndMonth: '',
            workDateEndYear: '',
            position: '',
            weeklySalaryInitial: null,
            weeklySalaryFinal: null,
            quittingReason: null,
            supervisorName: null,
            supervisorPosition: null,
          },
          {
            order: 2,
            companyName: '',
            companyPhoneNumber: null,
            companyCity: null,
            durationInCompany: '',
            workDateStartMonth: '',
            workDateStartYear: '',
            workDateEndMonth: '',
            workDateEndYear: '',
            position: '',
            weeklySalaryInitial: null,
            weeklySalaryFinal: null,
            quittingReason: null,
            supervisorName: null,
            supervisorPosition: null,
          }
        ],
        personalReferences: [
          {
            name: null,
            phoneNumber: null,
            relationship: null,
          },
          {
            name: null,
            phoneNumber: null,
            relationship: null,
          },
          {
            name: null,
            phoneNumber: null,
            relationship: null,
          }
        ],
        hasVehicle: null,
        hasDriverLicense: null,
        driverLicenseType: null,
        driverLicenseEmitter: null,
        driverLicenseState: null,
        isPublicTransportUser: null,
        travelCount: null,
        hasDebt: null,
        creditorName: [],
        unionAffiliated: null,
        unionName: null,
        shirtSize: null,
        dependantCount: null,
        housemates: null,
        hasChildren: null,
        childrenCount: null,
        childrenCare: null,
        childrenAges: [],
        hasLivedInUSA: null,
        yearsInEnsenada: null,
        monthsInEnsenada: null,
        availabilityDate: null,
        isFormerEmployee: null,
        hasRelativeEmployee: null,
        relativeEmployeeName: null,
        hasExternalIncome: null,
        jobPostSource: '',
        jobPostSourceOther: null,
        hasAlternativeApplication: null,
        alternativeApplication: [],
        recaptcha: false
      },
      formSubmited: false,
      options: {
        //dropdowns
        genders: [
          {text: 'Hombre', id: 'M'},
          {text: 'Mujer', id: 'F'},
          {text: 'Otro', id: 'O'},
          {text: 'Prefiero No Contestar', id: 'NA'}
        ],
        maritalStatus: [
          {text: 'Soltero', id: 'SINGLE'},
          {text: 'Casado', id: 'MARRIED'},
          {text: 'Divorciado', id: 'DIVORCED'},
          {text: 'Viudo', id: 'WIDOWED'},
          {text: 'Unión Libre', id: 'DOMESTIC_PARTNERSHIP'},
          {text: 'Separado', id: 'SEPARATED'},
        ],
        schoolCertificate: [
          {id: 'YES',text: 'Sí'},
          {id: 'NO',text: 'No'},
          {id: 'IN_PROGRESS',text: 'En Trámite'},
        ],
        schoolGrades: {
          elementary: [
            {id: 'GRADE_1ST',text: '1er Grado'},
            {id: 'GRADE_2ND',text: '2do Grado'},
            {id: 'GRADE_3RD',text: '3er Grado'},
            {id: 'GRADE_4TH',text: '4to Grado'},
            {id: 'GRADE_5TH',text: '5to Grado'},
            {id: 'GRADE_6TH',text: '6to Grado'}
          ],
          juniorHigh: [
            {id: 'GRADE_1ST',text: '1er Grado'},
            {id: 'GRADE_2ND',text: '2do Grado'},
            {id: 'GRADE_3RD',text: '3er Grado'},
          ],
          university: [
            {id: 'GRADE_1ST',text: '1er Grado'},
            {id: 'GRADE_2ND',text: '2do Grado'},
            {id: 'GRADE_3RD',text: '3er Grado'},
            {id: 'GRADE_4TH',text: '4to Grado'},
            {id: 'GRADE_5TH',text: '5to Grado'},
            {id: 'GRADE_6TH',text: '6to Grado'},
            {id: 'GRADE_7TH',text: '7mo Grado'},
            {id: 'GRADE_8TH',text: '8vo Grado'},
            {id: 'GRADE_9TH',text: '9no Grado'},
            {id: 'GRADE_10TH',text: '10mo Grado'},
            {id: 'GRADE_11TH',text: '11vo Grado'},
            {id: 'GRADE_12TH',text: '12vo Grado'},
          ],
        },
        schoolModalities: [
          {id: 'SEMESTER',text: 'Semestral'},
          {id: 'QUARTER',text: 'Cuatrimestral'},
          {id: 'PART_TIME',text: 'Semiescolarizado'},
          {id: 'OPEN',text: 'Abierta'},
          {id: 'SPECIAL_COURSE', text: 'Curso especial'}
        ],
        states: [
          {
          id: "Aguascalientes",
          text: "Aguascalientes"
          },
          {
            id: "Baja California",
            text: "Baja California"
          },
          {
            id: "Baja California Sur",
            text: "Baja California Sur"
          },
          {
            id: "Campeche",
            text: "Campeche"
          },
          {
            id: "Chiapas",
            text: "Chiapas"
          },
          {
            id: "Chihuahua",
            text: "Chihuahua"
          },
          {
            id: "Coahuila",
            text: "Coahuila"
          },
          {
            id: "Colima",
            text: "Colima"
          },
          {
            id: "Ciudad de México",
            text: "Ciudad de México"
          },
          {
            id: "Durango",
            text: "Durango"
          },
          {
            id: "Guanajuato",
            text: "Guanajuato"
          },
          {
            id: "Guerrero",
            text: "Guerrero"
          },
          {
            id: "Hidalgo",
            text: "Hidalgo"
          },
          {
            id: "Jalisco",
            text: "Jalisco"
          },
          {
            id: "México",
            text: "México"
          },
          {
            id: "Michoacán",
            text: "Michoacán"
          },
          {
            id: "Morelos",
            text: "Morelos"
          },
          {
            id: "Nayarit",
            text: "Nayarit"
          },
          {
            id: "Nuevo León",
            text: "Nuevo León"
          },
          {
            id: "Oaxaca",
            text: "Oaxaca"
          },
          {
            id: "Puebla",
            text:"Puebla"
          },
          {
            id: "Querétaro",
            text: "Querétaro"
          },
          {
            id: "Quintana Roo",
            text: "Quintana Roo"
          },
          {
            id: "San Luis Potosí",
            text: "San Luis Potosí"
          },
          {
            id: "Sinaloa",
            text: "Sinaloa"
          },
          {
            id: "Sonora",
            text: "Sonora"
          },

          {
            id: "Tabasco",
            text: "Tabasco"
          },
          {
            id: "Tamaulipas",
            text: "Tamaulipas"
          },
          {
            id: "Tlaxcala",
            text: "Tlaxcala"
          },

          {
            id: "Veracruz",
            text: "Veracruz"
          },
          {
            id: "Yucatán",
            text: "Yucatán"
          },
          {
            id: "Zacatecas",
            text: "Zacatecas"
          }
        ],
        jobPostSources: [
          {id: 'SAME_PAGE',text: 'A través de esta misma página'},
          {id: 'PAPER',text: 'Anuncio en papel'},
          {id: 'INDEED',text: 'Indeed'},
          {id: 'PERIPHONE',text: 'Perifoneo (Automóvil)'},
          {id: 'ENSENADAHOY',text: 'www.ensenadahoy.com'},
          {id: 'ENSENADANET',text: 'www.ensenada.net'},
          {id: 'FACEBOOK',text: 'Facebook'},
          {id: 'FRIEND',text: 'A través de un amigo'},
          {id: 'OTHER',text: 'Otro'},
        ],
        //buttons
        yesNo: [
          {id: true,label: 'Sí'},
          {id: false,label: 'No'},
        ],
        schoolModality: [
          {id: 'SEMESTER', label: 'Semestres'},
          {id: 'QUARTER', label: 'Cuatrimestres'},
        ],
        yesNoPartial: [
          {id: 'YES',label: 'Sí'},
          {id: 'NO',label: 'No'},
          {id: 'PARTIAL',label: 'Parcial'},
        ],
        yesNoEnum: [
          {id: 'YES',label: 'Sí'},
          {id: 'NO',label: 'No'},
        ],
        isFirstJob: [
          {id: false,label: 'Sí'},
          {id: true,label: 'No, este será mi primer empleo'},
        ],
        driverLicenseTypes: [
          {id: 'A',label: 'A'},
          {id: 'B',label: 'B'},
          {id: 'C',label: 'C'},
          {id: 'D',label: 'D'},
        ],
        driverLicenseEmitters: [
          {id: 'STATE',label: 'Estatal'},
          {id: 'FEDERAL',label: 'Federal'},
        ],
        creditors: [
          {id: 'Infonavit',label: 'Infonavit'},
          {id: 'Fonacot',label: 'Fonacot'},
          {id: 'Pensión Alimenticia',label: 'Pensión Alimenticia'},
          {id: 'Mejoravit',label: 'Mejoravit'},
        ],
        shirtSizes: [
          {id: 'XS',label: 'XS'},
          {id: 'S',label: 'S'},
          {id: 'M',label: 'M'},
          {id: 'L',label: 'L'},
          {id: 'XL',label: 'XL'},
          {id: 'XXL',label: 'XXL'},
          {id: 'XXXL',label: 'XXXL'},
        ],
        dateAvailavility: [],
      },
      validateForm: null,
      loading: false,
      jobs: null,
      recaptchaSiteKey: process.env.VUE_APP_6LdeG-UjAAAAABaQHrc8E1lFqdty83dX10EAd99R,
      disableHighSchoolCertified: false,
      disableUnivercityCertified: false,
      downDownSelect: 'Selecciona',
      calendarMessage: "Debes tener 18 años cumplidos para ser parte de nuestro equipo de trabajo. Si aun no eres mayor de edad, puedes continuar con tu solicitud siempre y cuando cumplas la mayoría de edad antes del 10 de Julio de ",
      currentYear: new Date().getFullYear(),
      isCalendarOpen: false,
      monthsOptions: MONTHS,
    }
  },
  beforeRouteLeave (to, from, next) {
    const formTouched = this.$refs.validationObserver.$data.flags.touched;
    if(formTouched && !this.formSubmited)
      this.$refs.leaveApplicationModal.openModal(next);
    else
      next()
  },

  async mounted() {
    await this.getJobInfo();
    this.today = moment().format('YYYY-MM-DD');
    this.options.dateAvailavility.push({id: this.today,label: 'Inmediatamente'});
    this.jobs = await this.getJobs();
    this.initDate();
  },
  computed: {
    displayElementarySchool() {
      let display = this.form.elementarySchoolAccredited && this.form.elementarySchoolAccredited !== 'NO';

      let data = 'elementarySchool'

      if(display) {
        data = this.form.elementarySchoolAccredited === 'PARTIAL' ? 'elementarySchool' : data;
        this.deleteData(data);
      } else {
        this.deleteData(data);
      }

      return display;

    },
    displayJuniorHighSchool() {
      let display = this.displayElementarySchool && (this.form.juniorHighSchoolAccredited && this.form.juniorHighSchoolAccredited !== 'NO');

      let data = 'juniorHighSchool'

      if(display) {
        data = this.form.juniorHighSchoolAccredited === 'PARTIAL' ? 'partialJuniorHighSchool' : data;
        this.deleteData(data);
      } else {
        this.deleteData(data);
      }

      return display;
    },
    displayHighSchool() {
      let display = this.displayJuniorHighSchool && (this.form.highSchoolAccredited && this.form.highSchoolAccredited !== 'NO');
      let data = 'highSchool'

      if(display) {
        data = this.form.highSchoolAccredited === 'PARTIAL' ? 'partialHighSchool' : data;
        this.deleteData(data);
      } else {
        this.deleteData(data);
      }

      return display;
    },
    displayUniversity() {
      let display = this.displayHighSchool && (this.form.universityAccredited && this.form.universityAccredited !== 'NO');
      let data = 'university'

      if(display) {
        data = this.form.universityAccredited === 'PARTIAL' ? 'partialUniversity' : data;
        this.deleteData(data);
      } else {
        this.deleteData(data);
      }

      return display;
    },
    displayCurrentlyStudying() {
      return this.form.currentlyStudying ? true : this.deleteData('currentlyStudying');
    },
    displayAlternativeApplication() {
      return this.form.hasAlternativeApplication ? true : this.deleteData('alternativeApplication');
    },
    hasElementarySchool() {
      return this.form.elementarySchoolAccredited == 'YES';
    },
    hasJuniorHighSchool() {
      return this.hasElementarySchool && this.form.juniorHighSchoolAccredited == 'YES';
    },
    hasHighSchool() {
      return this.hasJuniorHighSchool && this.form.highSchoolAccredited == 'YES';
    },
    hasUniversity() {
      return this.hasHighSchool && this.form.universityAccredited == 'YES';
    },
    isElementarySchoolPartial() {
      return this.form.elementarySchoolAccredited === 'PARTIAL';
    },
    isJuniorHighSchoolPartial() {
      return this.form.juniorHighSchoolAccredited === 'PARTIAL';
    },
    isHighSchoolPartial() {
      return this.form.highSchoolAccredited === 'PARTIAL' || this.form.highSchoolAccredited == 'YES';
    },
    isUniversityPartial() {
      return this.form.universityAccredited === 'PARTIAL' || this.form.universityAccredited === 'YES';
    },
    hasAnyGradeCertificate() {
      return (
        (this.hasElementarySchool && this.form.elementarySchoolCertified == 'YES') ||
        (this.hasJuniorHighSchool && this.form.juniorHighSchoolCertified == 'YES') ||
        (this.hasHighSchool && this.form.highSchoolCertified == 'YES') ||
        (this.hasUniversity && this.form.universityCertified == 'YES')
      )
    },
    formValid() {
      return this.form.recaptcha && !this.loading;
    },
    hasProfessionalLicense() {
      return this.form.professionalLicense;
    },
    highSchoolGradeOptions() {
      if(!this.form.highSchoolModality)
        return [];

      let mode, end;
      if(this.form.highSchoolModality == 'SEMESTER') {
        mode = 'Semestre';
        end = 6;
      } else {
        mode = 'Cuatrimestre';
        end = 9;
      }

      return grades.slice(0, end).map(grade => {
        return {
          ...grade,
          text: grade.text + ' ' + mode,
        }
      })
    },
    universityGradeOptions() {
      if(!this.form.universityModality)
        return [];

      const mode = this.form.universityModality == 'SEMESTER' ? 'Semestre' :'Cuatrimestre';

      return grades.map(grade => {
        return {
          ...grade,
          text: grade.text + ' ' + mode,
        }
      })
    },
    availableJobs() {
      let currentJobId = this.$route.params.id;
      let jobs = this.jobs;

      if(!jobs)
        return ;

      if(!this.form.hasDriverLicense) {
        jobs = jobs.filter(job => job.area !== 'Chofer');
      }

      return jobs
          .filter( job => job.id !== currentJobId)
          .map( job => Object.assign({}, {
            id: job.titleId,
            label: job.title,
            area: job.area
          }))
    },
    needProfessionalLicense() {
      return this.form.alternativeApplication && this.form.alternativeApplication.includes("NURSING_TECHNICIAN_OR_PARAMEDIC");
    },
    isDriver() {
      return this.jobInfo.area ==='Chofer'
          || (this.form.alternativeApplication && (this.form.alternativeApplication.includes("WATER_PIPE_TRUCK_DRIVER")
              || this.form.alternativeApplication.includes("MANUAL_TRANSMISSION_STAKE_BED_TRUCK_DRIVER")))
    },
    needHighSchoolOrUniversityCertificate() {
      const jobs = [
        'WAREHOUSE_ADMINISTRATIVE_ASSISTANT',
        'ACCOUNTING_ASSISTANT',
        'INVOICING_AND_VENDOR_PAYMENTS_ADMINISTRATIVE_ASSISTANT',
        'HUMAN_RESOURCES_ADMINISTRATIVE_ASSISTANT',
        'ON_SITE_IT_AUXILIARY_TECHNICIAN',
        'AUTO_REPAIR_SHOP_ADMINISTRATIVE_ASSISTANT',
        'STORE_ADMINISTRATIVE_ASSISTANT',
        'ACCESS_CONTROL',
        'BILINGUAL_ACCESS_CONTROL',
        'COLLECTOR_AND_GUEST_RELATIONS',
        'BILINGUAL_COLLECTOR_AND_GUEST_RELATIONS'
      ];

      const { titleId } = this.jobInfo || {};
      const alternativeJobs = this.form.alternativeApplication
        ? [... this.form.alternativeApplication]
        : [];

      if (titleId) {
        alternativeJobs.push(titleId);
      }

      return jobs.some(job => alternativeJobs.includes(job));

    },
    
    sixteenYearsAgo(){
        const today = new Date();
        const currentYear = today.getFullYear();

        const sixteenYearsAgo = currentYear - LOWER_AGE_LIMIT;
        today.setFullYear(sixteenYearsAgo)
        today.setMonth(6)
        today.setDate(10)
        return today;
    },
    sixtyYearsAgo(){
        const today = new Date();
        const currentYear = today.getFullYear();

        const sixtyYearsAgo = currentYear - UPPER_AGE_LIMIT;
        today.setFullYear(sixtyYearsAgo)

        return today;
    },
    yearsOptions() {
        const currentYear = new Date().getFullYear();
        const yearsOptions = [];

        for (let i = 0; i <= 50; i++) {
            const year = currentYear - i;
            yearsOptions.push({ id: year, text: year });
        }

        return yearsOptions;
    },
    isWorkDateStartNull(){
        return this.form.applicantHistory[0].workDateStartYear != '' 
              && this.form.applicantHistory[0].workDateStartMonth != ''
    },
    isWorkDateEndNull(){
        return this.form.applicantHistory[0].workDateEndYear != '' 
              && this.form.applicantHistory[0].workDateEndMonth != '' 
    },
    isWorkDateValid(){
        if(this.isWorkDateStartNull && this.isWorkDateEndNull) {
            if(Number(this.form.applicantHistory[0].workDateEndYear) > Number(this.form.applicantHistory[0].workDateStartYear)) {
                return true
            }
            if(Number(this.form.applicantHistory[0].workDateEndYear) === Number(this.form.applicantHistory[0].workDateStartYear)) {
                const startMonthIndex = MONTHS.findIndex(month => month.id === this.form.applicantHistory[0].workDateStartMonth);
                const endMonthIndex = MONTHS.findIndex(month => month.id === this.form.applicantHistory[0].workDateEndMonth);

                if(startMonthIndex <= endMonthIndex){
                    return true;
                }
            }
            return false;
        }
        return true;
    },
    getDocumentName(){
        const name = this.form.applicantName ? this.form.applicantName.replace(/\s+/g, " ").replace(/ /g, "_") : '';
        const lastname = this.form.applicantFatherLastname ? this.form.applicantFatherLastname.replace(/\s+/g, " ").replace(/ /g, "_") : '';

        if(name != ''){
            if(lastname != '') {
                return `${name}_${lastname}`
            }
            return `${name}`
        }
        return '';
    }
  },
  methods: {
    initDate() {
      const today = new Date();
      const currentYear = today.getFullYear();
      const sixteenYearsAgo = currentYear - LOWER_AGE_LIMIT;

      let month = 0;
      let day = 0;

      if(Number(today.getMonth()) >= 6) {
        month = 6;
        if(Number(today.getDate()) > 10) {
          day = 10;
        } else {
          day = Number(today.getDate());
        }
      } else {
        month = Number(today.getMonth());
        day = Number(today.getDate());
      }

      let initDate = new Date(sixteenYearsAgo, month, day);

      this.form.birthDate = initDate;
    },
    isDateAvailable(date) {
      const today = new Date();
      const currentYear = today.getFullYear();

      const sixteenYearsAgo = currentYear - LOWER_AGE_LIMIT;
      const year = date.id.split("-")[0]
      const month = date.id.split("-")[1]
      const day = date.id.split("-")[2]

      let beforeLimitDate = Number(day) <= 10;
      let beforeLimitMonth = Number(month) < 7;
      let inLimitMonth = Number(month) == 7;
      let beforeLimitYear = Number(year) < sixteenYearsAgo;
      let inLimitYear = Number(year) == sixteenYearsAgo;

      return beforeLimitYear || beforeLimitMonth || (inLimitYear && inLimitMonth && beforeLimitDate);
    },
    selectedDay(date) {
          if(!date || !date.id) {
            return false;
          }
          return moment(this.date).format('YYYY-MM-DD') === date.id;
        },
    deleteData(value) {
      switch(value) {
        case 'elementarySchool':
          this.form.elementarySchoolName = null;
          this.form.elementarySchoolCertified = '';
          this.form.elementarySchoolGrade = '';
          this.form.juniorHighSchoolAccredited = null;
          this.form.highSchoolAccredited = null;
          this.form.universityAccredited = null;
          break;
        case 'partialElementarySchool':
          this.form.elementarySchoolCertified = '';
          this.form.elementarySchoolGrade = '';
          this.form.juniorHighSchoolAccredited = null;
          this.form.highSchoolAccredited = null;
          this.form.universityAccredited = null;
          break;
        case 'juniorHighSchool':
          this.form.juniorHighSchoolName = null;
          this.form.juniorHighSchoolGrade = '';
          this.form.juniorHighSchoolCertified = '';
          this.form.highSchoolAccredited = null;
          this.form.universityAccredited = null;
          break;
        case 'partialJuniorHighSchool':
          this.form.juniorHighSchoolGrade = '';
          this.form.juniorHighSchoolCertified = '';
          this.form.highSchoolAccredited = null;
          this.form.universityAccredited = null;
          break;
        case 'highSchool':
          this.form.highSchoolName = null
          this.form.highSchoolTechnicalDegree = null
          this.form.highSchoolCertified = ''
          this.form.highSchoolGrade = ''
          this.form.highSchoolModality = ''
          this.form.universityAccredited = null;
          break;
        case 'partialHighSchool':
          this.form.highSchoolTechnicalDegree = null
          this.form.highSchoolCertified = ''
          this.form.highSchoolGrade = ''
          this.form.highSchoolModality = ''
          this.form.universityAccredited = null;
          break;
        case 'university':
          this.form.universityName = null
          this.form.universityDegree = null
          this.form.universityCertified = ''
          this.form.universityGrade = ''
          this.form.universityModality = ''
          break;
        case 'partialUniversity':
          this.form.universityCertified = ''
          this.form.universityGrade = ''
          this.form.universityModality = ''
          this.form.universityDegree = null
          break;
        case 'currentlyStudying':
          this.form.currentlySchoolName = null
          this.form.currentlySchoolSchedule = null
          this.form.currentlySchoolDegree = null
          this.form.currentlySchoolModality = ''
          this.form.currentlySchoolGrade = ''
          this.form.currentlySchoolCity = null,
          this.form.currentlyDateInitOfCourse = null,
          this.form.currentlyCourseDuration = null
          break;
        case 'alternativeApplication':
          this.form.alternativeApplication = []
          this.form.professionalLicense = null
          this.form.professionalLicenseNumber = ''
          break;
        default:
          break;
      }
    },
    async getJobInfo() {
      this.jobInfo = await this.$store.dispatch('getJobPostsForWebById', this.$route.params.id);
      this.form.jobPostId = this.jobInfo.id;
    },
    checkWeight(event) {
      const re = /\d*\.?\d+/;
      let currentValue = event.target.value;

      if (!re.test(currentValue)) {
        this.form.weight = null;
        return false;
      }

      if (currentValue < 0) {
        currentValue = 0;
      } else if (currentValue > 999.99) {
        currentValue = 999.99;
      }

      this.form.weight = currentValue;
    },
    checkLastGradePointAverage(event) {
      const re = /\d/;
      let currentValue = event.target.value;

      if (!re.test(currentValue)) {
        this.form.lastGradePointAverage = null;
        return false;
      }

      if (currentValue < 0) {
        currentValue = 0;
      } else if (currentValue > 100) {
        currentValue = 100;
      }

      this.form.lastGradePointAverage = currentValue;
    },
    goBack() {
      this.$router.push({
        name: 'job-details',
        params: {
          id: this.$route.params.id
        }
      });
    },
    async sendApplication() {

      const isWorkDateValid = this.form.isFirstJob ? this.form.isFirstJob : this.isWorkDateValid && this.isWorkDateStartNull && this.isWorkDateEndNull
      this.validateForm = await this.$refs.validationObserver.validate() && isWorkDateValid;

      if(this.validateForm){
        this.loading = true;
        try {
          const childrenAges = this.concatArrayByCharacter(this.form.childrenAges, ',');
          const creditorName = this.concatArrayByCharacter(this.form.creditorName, ', ');
          const extraCommand = {
            childrenAges,
            creditorName
          };

          let command = { ...this.form, ...extraCommand };
          let jobApplication = {};

          try {
            jobApplication = await this.$store.dispatch('sendJobApplication', this.cleanForm(command));
            this.formSubmited = true;
            this.$store.dispatch('message', { type: 'success', message: 'Solicitud enviada' });
          } catch (e) {
            this.$store.dispatch('message', {type: 'error', message: 'Ocurrió un error, inténtelo nuevamente'});
          }

          try {
            await this.sendFilesAndEmail(jobApplication.id);
          } catch (e) {
            this.displayMessage('error', 'Ocurrió un error al enviar los documentos de solicitud');
            this.goBack();
            return;
          }

          if (jobApplication !== undefined) {
            this.goBack();
          }

        } catch (error) {
          this.$store.dispatch('message', {type: 'error', message: 'Ocurrió un error, inténtelo nuevamente'});
        } finally {
          this.loading = false;
        }
      }
    },
    concatArrayByCharacter(array, character) {
      if (!array) return null;

      return array.filter(value => value !== null && value !== undefined && value !== '').join(character);
    },
    async sendFilesAndEmail(id) {
      const command = {
        id
      };

      const files = this.$refs.fileWrapper.getFiles();
      if (files.entries().next().value) {
        command.files = files;
      }

      await this.$store.dispatch('sendJobApplicationEmails', command);
    },
    checkReference(reference) {
      return reference.name !== '' && reference.name !== null
          && reference.phoneNumber !== '' && reference.phoneNumber !== null
          && reference.relationship !== '' && reference.relationship !== null
    },
    checkHistory(history) {
      return history.companyName !== '' && history.companyName !== null
          && history.durationInCompany !== '' && history.durationInCompany !== null
          && history.workDateStartMonth !== '' && history.workDateStartMonth !== null
          && history.workDateStartYear !== '' && history.workDateStartYear !== null
          && history.workDateEndMonth !== '' && history.workDateEndMonth !== null
          && history.workDateEndYear !== '' && history.workDateEndYear !== null
          && history.position !== '' && history.position !== null
          && history.weeklySalaryInitial !== '' && history.weeklySalaryInitial !== null
          && history.weeklySalaryFinal !== '' && history.weeklySalaryFinal !== null
          && history.quittingReason !== '' && history.quittingReason !== null
          && history.supervisorName !== '' && history.supervisorName !== null
          && history.supervisorPosition !== '' && history.supervisorPosition !== null
          && history.companyCity !== '' && history.companyCity !== null
    },
    cleanForm(form) {
      if(form.weight)
        form.weight = form.weight.toString().replace(/[^0-9.]/g, '');

      if(form.height)
        form.height = form.height.toString().replace(/[^0-9.]/g, '');

      if(!form.speaksEnglish)
        form.englishProficiencyPercentage = null;

      if(form.englishProficiencyPercentage)
        form.englishProficiencyPercentage = form.englishProficiencyPercentage.replaceAll('%', '');

      if(!form.hasIllness)
        form.illness = null;

      if(form.elementarySchoolGrade === '') {
        form.elementarySchoolGrade = null;
      }

      if(form.elementarySchoolCertified === '') {
        form.elementarySchoolCertified = null;
      }

      if(form.juniorHighSchoolGrade === '') {
        form.juniorHighSchoolGrade = null;
      }

      if(form.juniorHighSchoolCertified === '') {
        form.juniorHighSchoolCertified = null;
      }

      if(form.highSchoolGrade === '') {
        form.highSchoolGrade = null;
      }

      if(form.highSchoolCertified === '') {
        form.highSchoolCertified = null;
      }

      if(form.highSchoolModality === '') {
        form.highSchoolModality = null;
      }

      if(form.universityGrade === '') {
        form.universityGrade = null;
      }

      if(form.universityCertified === '') {
        form.universityCertified = null;
      }

      if(form.universityModality === '') {
        form.universityModality = null;
      }

      if(!form.currentlyStudying) {
        form.currentlySchoolGrade = null;
        form.currentlySchoolModality = null;
      }

      if(form.currentlySchoolGrade === '') {
        form.currentlySchoolGrade = null;
      }

      if(form.currentlySchoolModality === '') {
        form.currentlySchoolModality = null;
      }

      if(form.professionalLicenseNumber === '') {
        form.professionalLicenseNumber = null
      }

      if(!form.hasDriverLicense){
        form.driverLicenseState = null;
        form.driverLicenseEmitter = null;
        form.driverLicenseState = null;
      } else if(form.driverLicenseEmitter !== 'STATE') {
        form.driverLicenseState = null;
      }

      if(!form.isPublicTransportUser)
        form.travelCount = null;

      if(!form.hasDebt)
        form.creditorName = null;

      if(!form.unionAffiliated)
        form.unionName = null;

      if(!form.hasChildren){
        form.childrenCount = null;
        form.childrenAges = null;
      }

      if(!form.hasRelativeEmployee)
        form.relativeEmployeeName = null;

      if(form.isFirstJob){
        form.applicantHistory = null;
      } else {
        let applicantHistory = [];
        form.applicantHistory.forEach(ah => {

          if(ah.weeklySalaryInitial)
            ah.weeklySalaryInitial = ah.weeklySalaryInitial.replace(/\D/g, "");

          if(ah.weeklySalaryFinal)
            ah.weeklySalaryFinal = ah.weeklySalaryFinal.replace(/\D/g, "");

          if(this.checkHistory(ah))
            applicantHistory.push(ah);
        })
        form.applicantHistory = [];
        form.applicantHistory.push(...applicantHistory);
      }

      let personalReferences = [];
      form.personalReferences.forEach(r => {
        if(this.checkReference(r))
          personalReferences.push(r);
      });

      form.personalReferences = [];
      form.personalReferences.push(...personalReferences);

      if(form.jobPostSource != 'OTHER') {
        form.jobPostSourceOther = null;
      }

      if(form.gender != 'O') {
        form.otherGender = null;
      }

      if(form.alternativeApplication && form.alternativeApplication.length > 0) {
        form.alternativeApplication = form.alternativeApplication.join(',');
      } else {
        form.alternativeApplication = null;
      }

      return form;
    },
    onVerify(response) {
      if (response) this.form.recaptcha = true;
    },
    onExpired() {
      this.form.recaptcha = false;
    },
    validatePercentage() {
      let percentage = this.form.englishProficiencyPercentage.replace(/\D/g, "");
      if(!percentage || percentage < 0 ) this.form.englishProficiencyPercentage = '0';
      this.form.englishProficiencyPercentage = percentage > 100 ? '100%' : this.form.englishProficiencyPercentage.concat('%');
    },
    validateMoney(num, val) {
      let value = this.form.applicantHistory[num][val].replace(/\D/g, "");
      value = (!value || value < 0 ) ? null :  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.form.applicantHistory[num][val] = value ? '$'.concat(value, ' MXN') : value;
    },
    addSuffix(key, suffix) {
      if(this.validateForm)
        return;
      let value = this.form[key].toString().replace(/\D/g, "");
      this.form[key] = value ? value.slice(0, 2) + ' ' + suffix : value;
    },
    addDecimalSuffix(key, suffix, prefix) {
      if(this.validateForm)
        return;

      let pref = this.form[key] && this.form[key].toString().indexOf(".") > 1 ? this.form[key].toString().indexOf('.') : prefix;
      let value = this.form[key] ? this.form[key].toString().replace(/[^0-9.]/g, '') : null;
      value = value && value.toString().replace(/\D/g, "") && value.length > pref + 1
          ? value.slice(0, pref).toString().replace(/\D/g, "") + '.' + value.slice(pref, pref + 3).toString().replace(/\D/g, "")
          : value;
      this.form[key] = value ? value + ' ' + suffix : value;
    },
    async getJobs() {
      return await this.$store.dispatch('getJobPostsForWeb');
    },
    enableInputHighSchoolCertified(grade, grades) {
        this.disableHighSchoolCertified = grade === grades[grades.length - 1].id;
        if (!this.disableHighSchoolCertified) {
            this.form.highSchoolCertified = '';
        }
    },
    enableInputUnivercityCertified(grade, grades){
      this.disableUnivercityCertified = grade === grades[grades.length - 1].id;
    },
    updateFileNames() {
        const files = this.$refs.fileWrapper.$refs;

        Object.keys(files).forEach((clave) => {
            files[clave].updateFileName(this.getDocumentName);
        });
    }
  }
}
</script>

<style scoped lang="scss">

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
  .job-application {
    margin: 24px;
    .btn {
      cursor: pointer;
    }
    option {
      padding: 1rem;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: #272727;
    }

    .date-picker {
      border: 1px solid $brown-opacity-2-color;
      border-radius: 8px;
      position: relative;
      height: rem(48px);
      cursor: pointer;

      input {
        font-family: $font-primary;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: $light-gray;
        padding-left: 44px;
        padding-top: 13px;
        border: none;
        margin: 0;
        cursor: pointer;
        height: 100%;
      }
      &.--birth-date {
            border: 1px solid $brown-opacity-2-color;
            input {
                font-weight: 500;
            }
        }
      img {
        position: absolute;
        top: calc(50% - 9px);
        padding-left: 1rem;
        opacity: .6;
      }
    }
    &__header {
      display: grid;
      align-items: center;
      text-transform: uppercase;
      grid-template-columns: 1fr 168px;
      &__title h2 {
        font-size: 20px;
        line-height: 27px;
      }
      @include respond-to("small and down") {
        grid-template-columns: 1fr;
        button {
          display: none;
        }
      }
     }
    &__form {
      background: white;
      margin: 24px 0;
      padding: 16px;
      &__section {
        display: grid;
        grid-auto-flow: row;
        row-gap: 24px;
        margin-bottom: 24px;
        &__title {
          font-size: rem(24px);
          line-height: rem(33px);
          @include respond-to("medium and down") {
            font-size: rem(16px);
            line-height: rem(22px);
          }
        }
        .personal-references {
          overflow-x: auto;
          white-space: nowrap;
          td input {
            margin-bottom: 0px;
          }
          @include respond-to("small and down") {
            display: block;
          }
        }
        .columns {
          display: grid;
          grid-auto-flow: column;
          grid-gap: 16px;
          &--half {
            grid-auto-columns: 1fr 1fr;
          }
          &--quarter {
            grid-auto-columns: 0.5fr 0.5fr;
          }
          &--narrow {
            grid-column-gap: 32px;
            justify-items: start;
          }
          &--cars {
            grid-auto-columns: 280px 400px 320px;
          }
          &--union {
            grid-auto-columns: 370px 300px;
          }
          &--source {
            align-items: end;
            grid-auto-columns: 599px 262px;
          }
          @include respond-to("medium and down") {
            grid-auto-columns: unset;
            grid-auto-flow: row;
            .desktop {
              display: none;
            }
          }
          .history {
            overflow-x: auto;
            white-space: nowrap;
            @include respond-to("small and down") {
              display: block;
            }
          }
        }
      }
      .general {
        grid-template-columns: 256px 395px 285px;
        grid-gap: 16px;
        @include respond-to("medium and down") {
          grid-template-columns: 1fr;
        }
      }
      .personal {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 16px;
        @include respond-to("medium and down") {
          grid-template-columns: 1fr;
        }
      }
      .field {
        display: grid;
        position: relative;
        &--full-width {
          width: 100%;
          grid-column: 1 / 3;
          @include respond-to("medium and down") {
            grid-column: unset;
          }
        }
        &.--date::v-deep {
          .vc-popover-content-wrapper {
            .vc-popover-content {
              .vc-popover-content-wrapper {

                .vc-text-center {
                  color: white;
                }
                .vc-bg-yellow-100 {
                  background-color: gray;
                }
              }
            }
          }
        }

        &__job-select {
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 8px
        }

        .day-content {
          .day {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            letter-spacing: 0.05em;
            color: $dark-brown-color;
            cursor: pointer;
            text-align: center;
          }

          .total-amount {
            font-family: $font-primary;
            font-size: 9px;
            line-height: 8px;
            letter-spacing: 0.02em;
            color: $light-gray;
          }

          .selected {
            color: white;
          }

          @media (max-height: 700px) {
            .day {
              font-size: 12px;
              line-height: 18px;
            }

            .total-amount {
              font-size: 8px;
              line-height: 4px;
            }
          }
        }
        .disabled {
          opacity: 0.4;
          cursor: not-allowed !important;
        }

        &__title {
          opacity: 0.6;
          color: $dark-brown-color;
          letter-spacing: 0.05em;
          line-height: rem(16px);
          font-size: rem(16px);
          font-weight: 500;
          font-style: normal;
        }
        &__input {
          width: 100%;
          margin-bottom: 0;
          height: rem(48px);
          padding-left: rem(16px);
          font-family: $font-primary;
          border: 1px solid $brown-opacity-2-color;
          border-radius: 8px;
          background: none;
          font-size: 16px;
          &--error {
            border: solid 1px $red-color2;
          }
          &__travel {
            width: 25%;
            @include respond-to("medium and down") {
              width: 100%
            }
          }
          &__people {
            width: 37.5%;
            @include respond-to("medium and down") {
              width: 100%
            }
          }
        }
        &__error {
          display: block;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: $red-color2;
        }
        &__radio-buttons {
          align-self: center;
          &__modality::v-deep {

            &.button-container {
              height: 48px;
              max-width: 242px;

              button {
                height: 48px;
                max-width: 113px;
              }
            }
          }

          &__creditors::v-deep {
            &.button-container {
              @include respond-to("medium and down") {
                display: grid;
                grid-auto-flow: unset;
                grid-template-columns: 1fr 1fr;
                button {
                  width: 100%;
                }
                input {
                  width: 206%;
                }
              }
            }
          }
          &__shirt-size::v-deep {
            &.button-container {
              @include respond-to("small and down") {
                display: grid;
                grid-auto-flow: unset;
                grid-template-columns: 1fr 1fr;
                button {
                  width: 80%;
                }
              }
            }
          }
          &__history::v-deep {
            &.button-container {
              @include respond-to("small and down") {
                display: grid;
                grid-auto-flow: unset;
                grid-template-columns: 1fr 1fr;
                button {
                  width: 100%;
                  font-size: 10px;
                }
              }
            }
          }
        }

        .inline {
          display: grid;
          grid-auto-flow: column;
          grid-column-gap: 16px;
        }
        .professional-license {
          width: 50%;
          @include respond-to("medium and down") {
            width: auto;
          }
        }
      }
      .option-placeholder{
        color: #7E7E7E;
      }
      .required {
        color: red;
      }
      .split-column {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 2rem;
      }
      .split-column-3 {
        display: grid;
        grid-template-columns: auto 0.5fr 1fr;
        grid-gap: 2rem;
      }
      .children-ages {
        display: grid;
        grid-template-columns: repeat(auto-fill, 115px);
        grid-gap: 1rem;
      }
      .antiquity {
        display: grid;
        grid-gap: 1rem;
        @include respond-to("medium and up") {
          grid-template-columns: repeat(2, 1fr );
          >label {
            grid-column: span 2;
          }
        }
      }
      table {
        border: none;
        th, td {
          border: 1px solid black;
          padding: 13px 16px;
        }
        th {
          background: $dark-gray;
          &.empty {
            border: none;
            background: none;
          }
        }
        td {
          max-height: max-content;
          input {
            text-align: center;
            border: none;
            max-height: max-content;
            padding: 0;
          }
        }
        tbody th {
          text-align: left;
        }
      }

    }
    &__recaptcha {
      display: grid;
      justify-items: right;
      margin-bottom: 28px;
      width: 100%;
    }
    &__footer {
      height: 48px;
      .btn {
        border: none;
        float: right;
        width: 200px;
        &.mobile {
          display: none;
        }
        @include respond-to("medium and down") {
          width: 170px;
          &.mobile {
            display: unset;
            float: left;
          }
        }
      }
      .loader {
        margin-top: -8px;
        float: right;
        text-align: center;
        width: 200px;
        height: 48px;
        &.mobile {
          display: none;
        }
        @include respond-to("medium and down") {
          width: 170px;
          &.mobile {
            display: unset;
            float: left;
          }
        }
      }
    }
    &__error {
      text-align: right;
      padding: 16px;
      span {
        font-size: 16px;
        line-height: 16px;
        color: $red-color2;
      }
    }
  }
</style>